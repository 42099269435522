import React from "react"

import { ReactComponent as QuadioLogo } from "images/logo.svg"

import Styles from "./OverlayBox.module.css"

const OverlayBox: React.FC = ({ children }) => {
    return (
        <div className={Styles.wrap}>
            <div
                className={`${Styles.image} hidden mobile:block mobile:w-1/3 tablet:w-1/2`}
            />
            <div
                className={`${Styles.content} w-full mobile:w-2/3 tablet:w-1/2`}
            >
                {children}
            </div>
        </div>
    )
}

export const OverlayBoxWithBg: React.FC = ({ children }) => {
    return (
        <div className={Styles.overlay}>
            <OverlayBox>
                <div className={Styles.logowrap}>
                    <QuadioLogo />
                </div>
                {children}
            </OverlayBox>
        </div>
    )
}

export default OverlayBox
