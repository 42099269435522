import { useState, useContext } from "react"

import { RouteComponentProps } from "@reach/router"
import { TFunction } from "i18next"

import { userAdapter } from "global/ApiWorker/Api"
import LoadingContext from "context/LoadingProvider"

export interface ResetPasswordProps extends RouteComponentProps { }

export const useResetPasswordLogic = (
    t: TFunction,
    navigate: ResetPasswordProps["navigate"]
) => {
    const [email, setEmail] = useState("")

    const { setSaved } = useContext(LoadingContext)

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async event => {
        event.preventDefault()

        if (!email) {
            return
        }

        const data = await userAdapter.resetPassword(email)

        if (data) {
            setSaved({
                show: true,
                title: t("reset.password.done.title", "Anfrage erhalten!"),
                text: t(
                    "reset.password.done.text",
                    "Eine E-Mail ist zu Ihnen unterwegs."
                ),
            })

            navigate && navigate("/")
        }
    }

    return {
        handleSubmit,

        email,
        setEmail
    }
}
