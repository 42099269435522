import {
    createContext,
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from "react"

const noop = () => { }

export type PopdownManagerContextValue = {
    setReferenceNode: (node: HTMLElement | null) => void
    setPopdownNode: (node: HTMLElement | null) => void
    setLocked: Dispatch<SetStateAction<boolean>>
    setOpen: Dispatch<SetStateAction<boolean>>
    togglePopdown: () => void
    open: boolean
}

export const PopdownManagerContext = createContext<PopdownManagerContextValue>({
    setReferenceNode: noop,
    setPopdownNode: noop,
    setLocked: noop,
    setOpen: noop,
    togglePopdown: noop,
    open: false,
})

export type PopdownManagerProps = {
    onToggle?: (open: boolean) => void
    defaultOpen?: boolean
}

export const identityOrParent = (
    target: Element | null,
    parent: HTMLElement | null
) => {
    return (
        target &&
        parent &&
        (target.isSameNode(parent) || parent.contains(target))
    )
}

export const eventElem = window

export const usePopdownManagerLogic = ({
    onToggle,
    defaultOpen = false
}: PopdownManagerProps) => {
    const [listenerAttached, setListenerAttached] = useState(false)
    const [open, setOpen] = useState(defaultOpen)
    const [locked, setLocked] = useState(false)

    const [referenceNode, setReferenceNode] = useState<HTMLElement | null>(null)
    const [popdownNode, setPopdownNode] = useState<HTMLElement | null>(null)

    useEffect(() => {
        if (!onToggle || locked) {
            return
        }

        onToggle(open)
    }, [locked, onToggle, open])

    useEffect(() => {
        if (locked) {
            return
        }

        if (!open && !listenerAttached) {
            return
        }

        if (!open && listenerAttached) {
            eventElem.removeEventListener("click", handleBodyClick)
            setListenerAttached(false)

            return
        }

        function handleBodyClick(event: Event) {
            if (event.target && event.target instanceof Element) {
                if (
                    identityOrParent(event.target, referenceNode) ||
                    identityOrParent(event.target, popdownNode)
                ) {
                    return
                }
            }

            eventElem.removeEventListener(event.type, handleBodyClick)

            // trigger close
            setOpen(false)
        }

        eventElem.addEventListener("click", handleBodyClick)
        setListenerAttached(true)

        return function cleanup() {
            eventElem.removeEventListener("click", handleBodyClick)
        }
    }, [
        locked,
        open,
        listenerAttached,
        setOpen,
        setListenerAttached,
        referenceNode,
        popdownNode,
    ])

    const togglePopdown = useCallback(() => {
        setOpen(open => !open)
    }, [])

    const providerValue: PopdownManagerContextValue = {
        setReferenceNode: setReferenceNode,
        setPopdownNode: setPopdownNode,
        setLocked,
        setOpen,
        togglePopdown,
        open,
    }

    return {
        providerValue
    }
}
