import React from "react"
import { useTranslation } from "react-i18next"

import Button from "global/Button/Button"

import { ReactComponent as OneJokerSign } from "svg/joker-sign.svg"
import { ReactComponent as TwoJokerSign } from "svg/joker-two-sign.svg"
import { ReactComponent as CheckmarkCircle } from "svg/checkmark-circle-open.svg"

import Styles from "./JokerWrapper.module.css"

interface JokerWrapper {
    gameObj: any,
    operation: any,
    answers: any,
    activateJoker25: () => Promise<void | undefined>,
    activateJoker50: () => Promise<void | undefined>
}

const JokerWrapper: React.FC<JokerWrapper> = ({
    gameObj,
    operation,
    answers,
    activateJoker25,
    activateJoker50
}) => {
    const [t] = useTranslation()
    const isJoker50 = gameObj.joker50 === operation.sequence
    const isJoker25 = gameObj.joker25 === operation.sequence
    const jokerActive = isJoker25 || isJoker50

    return <div className={`${Styles.jokerWrapper} ${isJoker50 ? "mx-0 tablet:mx-auto" : "mx-auto"}`}>
        <Button
            appearance="joker"
            className={`${jokerActive ? "opacity-50" : ""} w-full my-2 mobile:w-auto mobile:mr-4 px-6 py-4 text-base ${isJoker50 ? Styles.jokerFadeout : ""}`}
            id="joker-25"
            disabled={gameObj.joker25 !== null}
            onClick={activateJoker25}
        >
            {
                (gameObj.joker25 !== null)
                    ? <CheckmarkCircle className="stroke-current mr-2 w-4" />
                    : <OneJokerSign className="mr-2" />
            }
            {t("game.joker.button", {
                defaultValue:
                    "1 Antwort entfernen",
                defaultValue_2:
                    "2 Antworten entfernen",
                count: answers.length / 4,
            })}
        </Button>
        <Button
            appearance="joker"
            className={`${jokerActive ? "opacity-50" : ""} w-full my-2 mobile:w-auto px-6 py-4 text-base ${isJoker25 ? Styles.jokerFadeout : ""}`}
            id="joker-50"
            disabled={gameObj.joker50 !== null}
            onClick={activateJoker50}
        >
            {
                (gameObj.joker50 !== null)
                    ? <CheckmarkCircle className="stroke-current mr-2 w-4" />
                    : <TwoJokerSign className="mr-2" />
            }
            {t("game.joker.button", {
                defaultValue:
                    "Antwort entfernen",
                defaultValue_2:
                    "Zwei Antworten Entfernen",
                defaultValue_plural:
                    "{{ count }} Antworten entfernen",
                count: answers.length / 2,
            })}
        </Button>
    </div>
}

export default JokerWrapper
