import React, { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"

import Button from "global/Button/Button"

import { ReactComponent as PlusCircleIcon } from "svg/plus-circle.svg"
import { ReactComponent as QuadioLogo } from "images/logo.svg"

import { useSignUpDepartmentsLogic, SignUpDepartmentsProps } from "hooks/logic/SignUp/useSignUpDepartmentsLogic"
import SignUpStep from "./SignUpStep/SignUpStep"

import Styles from "./SignUp.module.css"
import SignUpLoading from "./SignUpLoading/SignUpLoading"

const SignUpDepartments: React.FC<SignUpDepartmentsProps> = ({
    token,
    navigate
}) => {
    const [t] = useTranslation()
    const {
        handleSubmit,
        departments,
        updateDepartment,
        addEntry,
        skip,
        isTokenValid,
        loading
    } = useSignUpDepartmentsLogic(token, t)

    const handleDepartmentNameChange: React.ChangeEventHandler<HTMLInputElement> = ({
        currentTarget: { id, value },
    }) => {
        updateDepartment(id, value)
    }

    const submitDepartments = useCallback(() => {
        handleSubmit()

        navigate && navigate(`/sign-up/invites/${token}`)
    }, [handleSubmit, navigate, token])

    useEffect(() => {
        if (!isTokenValid()) {
            navigate && navigate("/")
        }
    }, [navigate, token, isTokenValid])

    if (loading) {
        return <SignUpLoading
            title={t("global.welcome", "Willkommen bei Quadio")}
            text={t("signup.departments.loading.text", "Lade Einrichtungen...")}
        />
    }

    return (
        <div className="container mx-auto">
            <div className="flex justify-between items-center w-full px-20 py-16 pb-0 mb-16">
                <QuadioLogo className={Styles.logo} />
                <Button
                    appearance={"transparent"}
                    onClick={skip}
                >
                    {t("global.skip", "Überspringen")}
                </Button>
            </div>
            <div className="flex flex-col w-1/2 mx-auto">
                <h2 className="flex mx-auto mb-4 text-2xl">{t("signup.departments.title", "Abteilungen anlegen")}</h2>
                <p className="mx-auto">{t("signup.departments.text", "Welche Abteilungen sollen in Quadio spielen?")}</p>
            </div>
            <SignUpStep step={2} max={3} />
            <div className="flex flex-col w-1/2 mb-12 mx-auto">
                <p className="mb-6">{t("global.departments", "Abteilungen")}</p>
                {departments.map((department, key) => {
                    return (
                        <div
                            className="mb-6"
                            key={department.id}
                        >
                            <input
                                autoFocus={key === 0}
                                id={department.id.toString()}
                                className={Styles.input}
                                type="text"
                                value={department.name}
                                placeholder={t(
                                    "signup.departments.placeholder.name",
                                    "Name der Abteilung"
                                )}
                                onChange={handleDepartmentNameChange}
                                autoComplete="new-department"
                            />
                        </div>
                    )
                })}
                <div className="flex">
                    <Button
                        appearance="transparent"
                        className="mx-auto"
                        onClick={addEntry}
                        rounded={false}
                    >
                        <PlusCircleIcon className="text-gray fill-current mr-3" />
                        {t("signup.departments.add_department", "Mehr hinzufügen")}
                    </Button>
                </div>
            </div>
            <div className="flex flex-col w-1/2 mb-12 mx-auto">
                <Button
                    appearance="primary"
                    className="justify-center mb-5"
                    onClick={submitDepartments}
                    rounded={false}
                >
                    {t("signup.departments.add", "Hinzufügen")}
                </Button>
                <Button
                    appearance="outline"
                    className="justify-center"
                    onClick={skip}
                    rounded={false}
                >
                    {t("signup.departments.later", "Später hinzufügen")}
                </Button>
            </div>
        </div>
    )
}

export default SignUpDepartments
