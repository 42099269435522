import { useContext, useCallback } from "react"
import { TFunction } from "i18next"

import { UserModel } from "@interweberde/prima-core"
import { usersContactAdapter } from "global/ApiWorker/Api"

import LoadingContext from "context/LoadingProvider"
import { QuadioNotification } from "global/NotificationService/NotificationService"
import { NotificationProps } from "../useNotificationLogic"

export const useContactRequestNotificationLogic = (
    t: TFunction,
    notification: QuadioNotification,
    onClick: NotificationProps["onClick"]
) => {
    const { setSaved, setFailed } = useContext(LoadingContext)

    const user = notification.payload.user as UserModel
    const fullname = user.name + " " + user.surname

    const accept = useCallback(
        (id: number) => async () => {
            if (!(await usersContactAdapter.accept(id))) {
                setFailed({
                    show: true,
                    title: t(
                        "notification.contact.accept.fail.title",
                        "Fehlgeschlagen!"
                    ),
                    text: t(
                        "notification.contact.accept.fail.text",
                        "Die Einladung konnte nicht akzeptiert werden."
                    ),
                })

                return
            }

            setSaved({
                show: true,
                title: t(
                    "notification.contact.accept.success.title",
                    "Einladung Akzeptiert!"
                ),
                text: t(
                    "notification.contact.accept.success.text",
                    "Die Einladung wurde akzeptiert."
                ),
            })

            onClick && onClick()
        },
        [setSaved, t, onClick, setFailed]
    )

    return {
        name: user.name,
        surname: user.surname,
        fullname,
        accept
    }
}
