import React from "react"

import { NotFound } from "pages/General/Error"
import AccessibilityRouter from "global/AccessibilityRouter"
import { RouteComponentProps } from "@reach/router"
import SignUpPage from "./SignUpUser/SignUpPage"
import SignUpDepartments from "./SignUpDepartments"
import SignUpInvites from "./SignUpInvites/SignUpInvites"

export interface SignUpProps extends RouteComponentProps { }

const SignUp: React.FC<SignUpProps> = ({ navigate }) => {
    return (
        <AccessibilityRouter>
            <SignUpPage path="/" />
            <SignUpDepartments path="departments/:token" />
            <SignUpInvites path="invites/:token" />
            <NotFound default />
        </AccessibilityRouter>
    )
}

export default SignUp
