import React, { ComponentProps } from "react"
import { Router } from "@reach/router"

const AccessibilityRouter: React.FC<ComponentProps<typeof Router>> = ({ primary, ref, ...props }) => {
    // TODO: use UserContext to read ax11 settings
    const ax11Enabled = false

    const isRouterPrimary = primary === undefined ? ax11Enabled : primary

    return <Router primary={isRouterPrimary} {...props} />
}

export default AccessibilityRouter
