import React from "react"
import { useTranslation } from "react-i18next"

import PopdownManager from "global/Popdown/PopdownManager"
import PopdownReference from "global/Popdown/PopdownReference"
import PopdownNode from "global/Popdown/PopdownNode"

import Caret from "global/Dropdown/Caret"
import { ReactComponent as BellIcon } from "svg/bell.svg"

import Styles from "./NotificationDropdown.module.css"
import Notification from "global/NotificationDropdown/Notification"
import { NotificationDropdownProps, useNotificationDropdownLogic } from "hooks/logic/NotificationDropdown/useNotificationDropdownLogic"

const NotificationDropdown: React.FC<NotificationDropdownProps> = ({
    backend,
}) => {
    const [t] = useTranslation()
    const {
        setIsDropdownOpen,
        isBackend,
        unreadNotifications,
        notifications,
        refetchNotifications
    } = useNotificationDropdownLogic(backend)

    const hasNotifications = notifications.length > 0

    return (
        <PopdownManager onToggle={setIsDropdownOpen}>
            <PopdownReference>
                {({ togglePopdown }) => ({ ref }) => (
                    <button
                        onClick={togglePopdown}
                        className={
                            isBackend
                                ? Styles.notificationTogglerBackend
                                : Styles.notificationToggler
                        }
                        ref={ref}
                    >
                        <BellIcon
                            className={
                                isBackend ? Styles.iconBackend : Styles.icon
                            }
                        />
                        {unreadNotifications > 0 && (
                            <span className={Styles.bell}></span>
                        )}
                    </button>
                )}
            </PopdownReference>
            <PopdownNode>
                {() => ({ ref, style, placement, arrowProps }) => {
                    return (
                        <div
                            className="absolute mt-1 bg-white rounded-lg shadow-lg z-10"
                            ref={ref}
                            style={{
                                ...style,
                                width: hasNotifications
                                    ? "432px"
                                    : "360px"
                            }}
                        >
                            <div className="p-4 border-b border-gray-muted">
                                <p className="text-xs font-bold text-left uppercase">
                                    {t("notification.dropdown.title", "Benachrichtigungen")}&nbsp;({notifications.length})
                                </p>
                            </div>

                            {hasNotifications && (
                                <ol>
                                    {notifications.map(notification => {
                                        const seen = notification.seen

                                        return (
                                            <li
                                                className={`${
                                                    Styles.notificationListItem
                                                } ${
                                                    seen ? Styles.past : ""
                                                }`}
                                                key={`search-${notification.id}`}
                                            >
                                                <Notification
                                                    notification={notification}
                                                    onClick={
                                                        refetchNotifications
                                                    }
                                                />
                                            </li>
                                        )
                                    })}
                                </ol>
                            )}

                            {!hasNotifications && (
                                <ol>
                                    <li className="p-10 text-center">
                                        <h3 className="text-base font-bold">
                                            {t(
                                                "notification.empty.title",
                                                "Keine Benachrichtigungen bisher"
                                            )}
                                        </h3>
                                        <p className="text-14 p-2">
                                            {t(
                                                "notification.empty.description",
                                                "Besuche unseren Hilfe-Bereich, um mehr über die Anwendung zu Quadio zu lernen."
                                            )}
                                        </p>
                                    </li>
                                </ol>
                            )}

                            <Caret
                                className="text-white"
                                placement={placement}
                                ref={arrowProps.ref}
                                style={arrowProps.style}
                            />
                        </div>
                    )
                }}
            </PopdownNode>
        </PopdownManager>
    )
}

export default NotificationDropdown
