import React from "react"
import { useTranslation } from "react-i18next"

import Styles from "./LogoutPage.module.css"
import ButtonStyleLink from "../Button/ButtonStyleLink"

const LogoutPage: React.FC = () => {
    const [t] = useTranslation()

    return (
        <div className={Styles.overlay}>
            <div className="bg-white z-10 fixed flex flex-wrap inset-0 w-full h-full p-8">
                <div className="w-full max-w-3xl m-auto">
                    <div className="text-center mb-4">
                        <h1 className="text-4xl mb-4">
                            {t("pages.logout.title", "Sie sind ausgeloggt")}
                        </h1>
                        <p className="text-center mb-4">
                            {t(
                                "pages.logout.goodbye",
                                "Sie wurden erfolgreich ausgeloggt. Bis zum nächsten Mal"
                            )}
                        </p>
                        <div className="flex mb-2 mt-8 justify-center">
                            <ButtonStyleLink
                                to="/"
                                className="uppercase px-6"
                                appearance="primary"
                            >
                                {t("logout.page.button", "Zum Login")}
                            </ButtonStyleLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogoutPage
