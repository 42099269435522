import React, { useCallback, useEffect, useState } from "react"
import { UserModel } from "@interweberde/prima-core"
import debugLib from "debug"
import useAbortable from "hooks/useAbortable"
import { authAdapter } from "global/ApiWorker/Api"
import { proxy } from "comlink"

type UseAuthRet = {
    authenticated: boolean | null
    user: UserModel | null
    setUser: React.Dispatch<React.SetStateAction<UserModel | null>>
    refetchUser: () => Promise<void>
    logout: () => Promise<void>
}

export const useAuth = (callee: string): UseAuthRet => {
    const [authenticated, setAuthenticated] = useState<boolean | null>(null)
    const [user, setUser] = useState<UserModel | null>(null)

    const debug = useCallback(debugLib("quadio:hooks:auth:" + callee), [callee])

    const abortableValidateAuthentication = useAbortable(async () => {
        return authAdapter.validateAuthentication()
    })

    useEffect(() => {
        if (authenticated === null) {
            debug(
                "will not register login subscriber - authentication state not determined!"
            )
            return
        }

        if (authenticated) {
            debug("will not register login subscriber - user is logged in!")
            return
        }

        debug("registering login subscriber")

        let unsubscribeId = ""
        authAdapter
            .subscribe(
                "login",
                proxy(success => {
                    if (!success) {
                        return
                    }

                    debug("user logged in")

                    setAuthenticated(true)
                })
            )
            .then(id => {
                unsubscribeId = id
            })

        return () => {
            authAdapter.unsubscribe("login", unsubscribeId).then(() => {
                debug("unsubscribed from login event")
            })
        }
    }, [authenticated, debug])

    useEffect(() => {
        if (authenticated === null) {
            debug(
                "will not register logout subscriber - authentication state not determined!"
            )
            return
        }

        if (!authenticated) {
            debug(
                "will not register logout subscriber - user is not logged in!"
            )
            return
        }

        debug("registering logout subscriber")

        let unsubscribeId = ""

        authAdapter
            .subscribe(
                "logout",
                proxy(success => {
                    if (!success) {
                        return
                    }

                    debug("user logged out")

                    setAuthenticated(false)
                })
            )
            .then(id => {
                unsubscribeId = id
            })

        return () => {
            authAdapter.unsubscribe("logout", unsubscribeId).then(() => {
                debug("unsubscribed from logout event")
            })
        }
    }, [authenticated, debug])

    useEffect(() => {
        abortableValidateAuthentication().then(isAuthenticated => {
            // check if request was canceled
            if (isAuthenticated === null) {
                return
            }

            debug(
                `check authenticated: user is ${
                    isAuthenticated ? "authenticated" : "unauthenticated"
                }`
            )

            setAuthenticated(isAuthenticated)
        })
    }, [abortableValidateAuthentication, debug])

    useEffect(() => {
        let unsubscribeId = ""

        authAdapter
            .subscribe(
                "userDidUpdate",
                proxy(async () => {
                    const user = await authAdapter.getUser(false)
                    debug("user did update", user)

                    setUser(user)
                })
            )
            .then(id => {
                unsubscribeId = id
            })

        debug("subscribed to user update events")

        return () => {
            authAdapter.unsubscribe("userDidUpdate", unsubscribeId).then(() => {
                debug("unsubscribed from user update event")
            })
        }
    }, [debug])

    const refetchUser = useCallback(async () => {
        await authAdapter.getUser(true)
    }, [])

    const logout = useCallback(() => {
        return authAdapter.logout()
    }, [])

    return {
        authenticated,
        user,
        setUser,
        refetchUser,
        logout,
    }
}
