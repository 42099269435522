import React from "react"

import Style from "./Dropdown.module.css"

interface DropdownProps {
    className?: string
}

const Dropdown: React.FC<DropdownProps> = ({ children, className }) => {
    return <div className={`${Style.dropdown} ${className}`}>{children}</div>
}

export default Dropdown
