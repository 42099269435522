import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import ButtonStyleLink from "global/Button/ButtonStyleLink"
import SearchField from "global/SearchField/SearchField"

import { ReactComponent as ServerErrorSvg } from "svg/no-access.svg"
import { ReactComponent as NoAccessSvg } from "svg/no-access.svg"
import { ReactComponent as NoConnectionSvg } from "svg/no-connection.svg"
import { ReactComponent as PageNotFoundSvg } from "svg/page-not-found.svg"

import { ReactComponent as PoweredByQuadio } from "svg/powered-by-quadio.svg"
import { RouteComponentProps } from "@reach/router"

import { ErrorProps } from "hooks/logic/General/useErrorLogic"

const Error: React.FC<ErrorProps> = ({ type }) => {
    const [t] = useTranslation()

    const errorTypes = useMemo(
        () => ({
            server: {
                Icon: ServerErrorSvg,
                title: t("errors.servererror.title", "Server Fehler"),
                description: t(
                    "errors.servererror.description",
                    "Ein Server-Fehler ist aufgetreten. Nehmen Sie Kontakt zu Ihrem Administrator auf, um den Sachverhalt auf zu klären."
                ),
            },
            access: {
                Icon: NoAccessSvg,
                title: t("errors.noaccess.title", "Kein Zugriff"),
                description: t(
                    "errors.noaccess.description",
                    "Sie haben keinen Zugriff auf die von Ihnen aufgerufene Seite. Nehmen Sie Kontakt zu Ihrem Administrator auf um den Sachverhalt auf zu klären."
                ),
            },
            connection: {
                Icon: NoConnectionSvg,
                title: t(
                    "errors.noconnection.title",
                    "Internet Verbindung unterbrochen"
                ),
                description: t(
                    "errors.noconnection.description",
                    "Die Internetverbindung ist unterbrochen. Bitte prüfen Sie Ihre Internet-Konnektivität."
                ),
            },
            notFound: {
                Icon: PageNotFoundSvg,
                title: t("errors.pagenotfound.title", "Seite nicht gefunden"),
                description: t(
                    "errors.pagenotfound.description",
                    "Die von Ihnen aufgerufene Seite wurde nicht gefunden. Nehmen Sie Kontakt mit Ihrem Administrator auf oder nutzen Sie die Suche, um Benutzer oder Thema zu finden."
                ),
            },
        }),
        [t]
    )

    const errorInfo = errorTypes[type]

    return (
        <div className="bg-white z-10 fixed flex flex-wrap inset-0 w-full h-full p-8">
            <div className="w-full max-w-3xl m-auto">
                <div className="text-center mb-4">
                    <errorInfo.Icon className="mx-auto mb-6" />
                    <h1 className="text-4xl mb-4">{errorInfo.title}</h1>
                    <p className="text-center mb-4">{errorInfo.description}</p>
                    <div className="flex mb-2 mt-8 justify-center">
                        <ButtonStyleLink to="/" appearance="primary">
                            {t(
                                "errors.servererror.button.back",
                                "Zur Übersicht"
                            )}
                        </ButtonStyleLink>
                    </div>
                    <div className="flex mb-2 mt-8 justify-center">
                        <SearchField
                            mode="frontend"
                            placeholder={t(
                                "errors.servererror.search.placeholder",
                                "z.B. Schulung"
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="flex self-end m-auto mb-6 w-full justify-center">
                <PoweredByQuadio className="h-8" />
            </div>
        </div>
    )
}

export default Error

export const NotFound: React.FC<RouteComponentProps> = () => (
    <Error type="notFound" />
)
