import React from "react"
import { useTranslation } from "react-i18next"

import Button from "../Button/Button"

import { OverlayBoxWithBg } from "global/OverlayBox/OverlayBox"

import { RestorePasswordProps, useRestorePasswordLogic } from "hooks/logic/RestorePassword/useRestorePasswordLogic"

const RestorePassword: React.FC<RestorePasswordProps> = ({
    token,
    navigate,
}) => {
    const [t] = useTranslation()

    const {
        isLoading,
        data,
        handleSubmit,
        canSubmit,
        backToLogin,

        newPassword,
        setNewPassword,

        confirmPassword,
        setConfirmPassword
    } = useRestorePasswordLogic(
        t,
        token,
        navigate
    )

    const handleNewPasswordChange: React.ChangeEventHandler<HTMLInputElement> = ({
        currentTarget: { value },
    }) => {
        setNewPassword(value.split(" ").join(""))
    }

    const handleConfirmPasswordChange: React.ChangeEventHandler<HTMLInputElement> = ({
        currentTarget: { value },
    }) => {
        setConfirmPassword(value.split(" ").join(""))
    }

    if (isLoading) {
        return <></>
    }

    if (!token || (!data && !isLoading)) {
        backToLogin()
    }

    return (
        <OverlayBoxWithBg>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <h2 className="mb-8 text-3xl">
                        {t("restorepassword.title", "Neues Kennwort vergeben")}
                    </h2>
                    <input
                        className="input focus:outline-none focus:shadow-outline"
                        id="newPassword"
                        type="password"
                        placeholder={t(
                            "restorepassword.form.placeholder.newpassword",
                            "Neues Kennwort"
                        )}
                        onChange={handleNewPasswordChange}
                        value={newPassword}
                        autoComplete="off"
                    />
                    <p className="text-gray-semilight text-sm py-2 border-t-2 border-transparent italic">
                        {t(
                            "restorepassword.form.description.newpassword",
                            "Passwörter müssen mindestens 8 Zeichen lang sein und mindestens eine Zahl enthalten."
                        )}
                    </p>
                </div>
                <div className="mb-4">
                    <input
                        className="input focus:outline-none focus:shadow-outline"
                        id="confirmPassword"
                        type="password"
                        placeholder={t(
                            "restorepassword.form.placeholder.confirmpassword",
                            "Neues Kennwort wiederholen"
                        )}
                        onChange={handleConfirmPasswordChange}
                        value={confirmPassword}
                        autoComplete="off"
                    />
                </div>
                <hr className="text-gray"></hr>
                <div className="mb-2 mt-8">
                    <Button
                        type="submit"
                        className="w-full"
                        appearance="primary"
                        disabled={!canSubmit}
                    >
                        {t("restorepassword.button.submit", "Bestätigen")}
                    </Button>
                </div>
                <div className="mb-6">
                    <Button
                        onClick={backToLogin}
                        className="w-full"
                        appearance="transparent"
                    >
                        {t("restorepassword.button.login", "Zum Login")}
                    </Button>
                </div>
            </form>
        </OverlayBoxWithBg>
    )
}

export default RestorePassword
