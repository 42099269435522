import React, { useEffect, useState } from "react"
import { Link } from "@reach/router"
import { useTranslation, Trans } from "react-i18next"

import Loading from "global/Loading/Loading"
import Progress from "global/Progress/Progress"
import Button from "global/Button/Button"
import JokerPopup from "../Joker/JokerPopup"
import JokerWrapper from "../Joker/JokerWrapper"

import { useHotkey } from "hooks/useHotkey"
import { QuestionWrapperProps, useQuestionWrapperLogic } from "hooks/logic/Frontend/Play/QuestionWrapper/useQuestionWrapperLogic"

import { ReactComponent as Crossmark } from "svg/crossmark.svg"
import { ReactComponent as CheckIcon } from "svg/checkmark-green.svg"
import { ReactComponent as CrossIcon } from "svg/crossmark-red.svg"
import { ReactComponent as KeyboardIcon } from "svg/keyboard.svg"
import { ReactComponent as PresentSign } from "svg/present.svg"

import { ReactComponent as ArrowRight } from "svg/arrow-short-right.svg"
import { ReactComponent as CheckmarkCircle } from "svg/checkmark-circle.svg"

import Styles from "./QuestionWrapper.module.css"

import "./QuestionWrapper.module.css"
import StyledTooltip from "global/Tooltip/StyledTooltip"

const KeyboardTooltip: React.FC = () => {
    const [t] = useTranslation()

    return <StyledTooltip
        placement="top"
        tooltipStyle={{
            width: "300px",
            top: "-5px"
        }}
        reference={<>
            <KeyboardIcon className="fill-current mr-2" />
            {t("game.tipps.title", {defaultValue: "Tastatur"})}
        </>}
        tooltip={<>
            <div className="p-4 bg-white rounded-xl shadow">
                <p className="text-sm text-primary font-sans leading-loose text-left">
                    <span className="font-bold">{t("game.tipps.hint", "Hinweis")}:</span><br />
                    <Trans i18nKey="game.tipps.text">
                        Sie können auch mit den <span className="font-bold">Zahlen 1-4</span> auf <span className="font-bold">Ihrer Tastatur</span> antworten und mit Enter bestätigen. Die Joker ziehen Sie mit <span className="font-bold">“Umschalt-1”</span> für eine Frage und <span className="font-bold">“Umschalt-2”</span> für zwei Fragen.
                    </Trans>
                </p>
            </div>
        </>}
    />
}

const QuestionWrapper: React.FC<QuestionWrapperProps> = ({
    game,
    onGameEnd,
}) => {
    const [t] = useTranslation()
    const {
        isLoading,
        sendAnswer,
        isAnswerCorrect,
        correctAnswer,
        selectAnswer,
        nextQuestion,
        activateJoker25,
        activateJoker50,
        question,
        answers,
        gameObj,
        operation,
        isValidated,
        selectedAnswer,
    } = useQuestionWrapperLogic(game, onGameEnd)

    const [correctAnswerIndex, setCorrectAnswerIndex] = useState(0)

    useHotkey(
        "1,2,3,4,5,6,7,8,9",
        e => {
            const number = parseInt(e.key, 10)

            if (isNaN(number) || number === 0) {
                return
            }

            if (!answers || answers.length < number) {
                return
            }

            selectAnswer(answers[number - 1].id)()
        },
        [answers, selectAnswer]
    )

    useHotkey(
        "shift+1",
        () => {
            activateJoker25().then()
        },
        [activateJoker25]
    )

    useHotkey(
        "shift+2",
        () => {
            activateJoker50().then()
        },
        [activateJoker50]
    )

    useHotkey(
        "enter",
        async () => {
            if (isValidated) {
                await nextQuestion()

                return
            }

            await sendAnswer()

            return
        },
        [
            sendAnswer,
            nextQuestion,
            isValidated
        ]
    )

    useEffect(() => {
        if (answers) {
            setCorrectAnswerIndex(
                1 +
                answers.findIndex(answer => {
                    return answer.id === correctAnswer
                })
            )
        }
    }, [answers, correctAnswer])

    useEffect(() => {
        if (isValidated && !isAnswerCorrect) {
            let elem = document.getElementById("answer-" + correctAnswerIndex)

            if (elem) {
                elem.scrollIntoView({ block: 'start', behavior: 'smooth' })
            }
        }
    }, [isValidated, correctAnswerIndex, isAnswerCorrect])

    if (isLoading) {
        return <Loading />
    }

    if (!question) {
        return <Loading />
    }

    if (answers === null) {
        return <Loading />
    }

    const currentProgress = operation.sequence - 1 + (isValidated ? 1 : 0)

    return (
        <>
            <div className="container flex justify-between align-content-center mx-auto px-4">
                <Link to="/" className="ml-0 my-auto">
                    <Crossmark />
                    <span className="sr-only">
                        {t("game.abort", { defaultValue: "Abbrechen" })}
                    </span>
                </Link>
                <div className="h-2 w-3/4 my-auto mx-4 hidden mobile:block">
                    <Progress
                        max={gameObj.number_of_questions}
                        value={currentProgress}
                    />
                </div>
                <div className="my-auto flex-shrink-0">
                    <span className="text-14 font-bold">Frage {operation.sequence}/{gameObj.number_of_questions}</span>
                </div>
            </div>
            <div className="container mx-auto">
                <div className={Styles.questionWrapper}>
                    <p className={Styles.question}>{question.question}</p>
                </div>

                <div className="flex flex-wrap -mx-2 tablet:-mx-4">
                    <div className={Styles.answerWrapper}>
                        {answers.map((answer, index) => {
                            const answerClass = [Styles.answer]

                            if (answer.id === selectedAnswer) {
                                answerClass.push(Styles.answerSelected)
                            }

                            if (isValidated && answer.id === correctAnswer) {
                                answerClass.push(Styles.answerCorrect)
                            }

                            if (isValidated && answer.id !== correctAnswer) {
                                answerClass.push(Styles.answerWrong)
                            }

                            if (answer.joker_rolled) {
                                answerClass.push(Styles.answerJoker)
                            }

                            return (
                                <div
                                    className="flex flex-grow w-full tablet:w-1/2 p-2 tablet:p-4"
                                    key={answer.id}
                                >
                                    <div
                                        className={answerClass.join(" ")}
                                        id={"answer-" + (index + 1)}
                                        onClick={selectAnswer(answer.id)}
                                        role="button"
                                    >
                                        <div className={Styles.answerNumberWrapper}>
                                            <div className={Styles.answerNumber}>{index + 1}</div>
                                        </div>
                                        <p className="mt-4 mobile:mt-0 mobile:ml-8">{answer.text}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            {!isValidated && <div className={Styles.validationWrap}>
                <div className="container mx-auto">
                    <div className="flex flex-wrap w-full mx-auto">
                        <div className="flex mobile:hidden bg-white">
                            <JokerPopup>
                                <JokerWrapper
                                    gameObj={gameObj}
                                    operation={operation}
                                    answers={answers}
                                    activateJoker25={activateJoker25}
                                    activateJoker50={activateJoker50}
                                />
                            </JokerPopup>
                        </div>

                        <div className="hidden tablet:flex w-1/4 my-auto">
                            <button className="my-auto flex-shrink-0">
                                {gameObj && <KeyboardTooltip />}
                            </button>
                        </div>
                        <div className="hidden mobile:flex mobile:w-3/4 mobile: flex-wrap tablet:w-1/2 items-center -mt-8">
                            <h5 className={`hidden mobile:flex w-full my-auto mr-4 text-base ${(gameObj.joker25 !== null || gameObj.joker50 === operation.sequence) ? "text-gray" : "text-primary"} pb-2 tablet:justify-center`}>
                                <PresentSign className="stroke-current mr-2" />{t("game.joker.title", "Joker")}
                            </h5>
                            <JokerWrapper
                                gameObj={gameObj}
                                operation={operation}
                                answers={answers}
                                activateJoker25={activateJoker25}
                                activateJoker50={activateJoker50}
                            />
                        </div>
                        <div className="flex mobile:w-1/4 ml-auto my-auto">
                            <Button
                                disabled={selectedAnswer === null}
                                onClick={sendAnswer}
                                id="continue"
                                appearance="gamePrimary"
                                className="px-8 ml-auto mr-0"
                            >
                                <p className="flex items-center">
                                    {t("game.checkQuestionShort", {defaultValue: "Prüfen"})}
                                    <CheckmarkCircle className="ml-2" />
                                </p>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>}
            {isValidated && <div
                className={[
                    Styles.validationWrap,
                    isAnswerCorrect
                        ? Styles.validationCorrect
                        : Styles.validationWrong,
                ].join(" ")}
            >
                <div className="container flex mx-auto items-center">
                    <div className="flex w-full">
                        <div className="flex w-2/3 items-center">
                            {isAnswerCorrect && (
                                <div className="hidden mobile:flex items-center">
                                    <div className="mx-0 my-auto">
                                        <CheckIcon />
                                    </div>
                                    <div className="align-self-center text-left mr-auto ml-6">
                                        <h3
                                            className={[
                                                "text-2xl normal-case",
                                                Styles.title,
                                            ].join(" ")}
                                        >
                                            {t("game.answer_validation.correct.title", "Richtig")}
                                        </h3>
                                        <p
                                            className={[
                                                "text-normal font-sans",
                                                Styles.text,
                                            ].join(" ")}
                                        >
                                            <Trans i18nKey="game.answer_validation.correct.text">
                                                Antwort {{correctAnswerIndex}} ist richtig!
                                            </Trans>
                                        </p>
                                    </div>
                                </div>
                            )}
                            {!isAnswerCorrect && (
                                <>
                                    <div className="mx-0 my-auto">
                                        <CrossIcon className="h-10 mobile:h-auto" />
                                    </div>
                                    <div className="align-self-center text-left mr-auto ml-0 mobile:ml-6">
                                        <h3
                                            className={[
                                                "mobile:text-2xl normal-case",
                                                Styles.title,
                                            ].join(" ")}
                                        >
                                            {t(
                                                "game.answer_validation.wrong.title",
                                                "Leider falsch"
                                            )}
                                        </h3>
                                        <p
                                            className={[
                                                "text-14 mobile:text-normal text-red-prettylight ",
                                                Styles.text,
                                            ].join(" ")}
                                        >
                                            <Trans i18nKey="game.answer_validation.wrong.text">
                                                Antwort {{correctAnswerIndex}} wäre richtig gewesen!
                                            </Trans>
                                        </p>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="flex w-1/3 my-auto">
                            <Button
                                onClick={nextQuestion}
                                id="continue"
                                appearance={
                                    isAnswerCorrect ? "green" : "red"
                                }
                                className="px-8 ml-auto mr-0"
                            >
                                {t("game.nextQuestion", {defaultValue: "Weiter"})}
                                <ArrowRight className="ml-2 stroke-current" />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default QuestionWrapper
