import { useCallback, useEffect, useMemo } from "react"
import throttle from "lodash/throttle"

const noop = () => {}
function useThrottled<T extends (...args: any[]) => any>(
    callback: T,
    delay: number
): T {
    const { throttledFn, cancel } = useMemo(() => {
        if (delay === 0) {
            return {
                throttledFn: callback,
                cancel: noop,
            }
        }

        const throttledFn = throttle(callback, delay)

        return {
            throttledFn,
            cancel: throttledFn.cancel,
        }
    }, [callback, delay])

    useEffect(() => {
        return () => {
            cancel()
        }
    }, [cancel])

    return useCallback(throttledFn, [throttledFn])
}

export default useThrottled
