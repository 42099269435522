import React from "react"
import { Link } from "@reach/router"

import { NotificationLinkProps, useNotificationLinkLogic } from "hooks/logic/NotificationDropdown/useNotificationLinkLogic"

const NotificationLink: React.FC<NotificationLinkProps> = ({ link: data }) => {
    const { link, text } = useNotificationLinkLogic(data)

    // TODO: add classname support

    if (!link || !text) {
        return null
    }

    return (
        <>
            <Link
                className="w-1/3 text-right uppercase text-blue font-sans self-center"
                to={link}
            >
                {text}
            </Link>
        </>
    )
}

export default NotificationLink
