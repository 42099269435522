import { RouteComponentProps } from "@reach/router";
import useAbortable from "hooks/useAbortable";
import { useCallback } from "react";
import { gameAdapter } from "global/ApiWorker/Api";
import { useEffect } from "react";

export interface GetGameProps
    extends RouteComponentProps<{
        topicId: number
    }> {
    onReceivedGame: (gameId: number) => void
}

export const useGetGameLogic = (
    topicId: GetGameProps["topicId"],
    onReceivedGame: GetGameProps["onReceivedGame"]
) => {
    const fetchGame = useAbortable(
        useCallback(async () => {
            if (!topicId) {
                return Promise.resolve(null)
            }

            return gameAdapter.getGameToPlay(topicId)
        }, [topicId])
    )

    useEffect(() => {
        fetchGame().then(data => {
            if (!data) {
                return
            }

            onReceivedGame(data.id)
        })
    }, [fetchGame, onReceivedGame])
}
