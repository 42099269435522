import React, { CSSProperties } from "react"

import Styles from "./Caret.module.css"

const Caret = React.forwardRef<
    HTMLDivElement,
    {
        className: string
        placement: string
        style: CSSProperties
    }
>(({ className, placement, style }, ref) => {
    return (
        <div
            className={`${Styles.caret} ${className}`}
            ref={ref}
            style={style}
            data-placement={placement}
        />
    )
})

export default Caret
