import { Duration } from "luxon"

export interface DisplayDurationProps {
    seconds: number
    short?: boolean
}

export const useDisplayDurationLogic = (seconds: DisplayDurationProps["seconds"]) => {
    const duration = Duration.fromObject({
        seconds,
    }).shiftTo("hour", "minute", "seconds")

    return {
        duration
    }
}
