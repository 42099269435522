import React from "react"

import { Link } from "@reach/router"

import { SearchResultProps } from "hooks/logic/SearchField/useSearchFieldLogic"
import { useUserSearchResultLogic } from "hooks/logic/SearchField/useUserSearchResultLogic"

import { ReactComponent as ChevronRight } from "svg/chevron-right-gray.svg"

const UserSearchResult: React.FC<SearchResultProps> = ({ result, mode }) => {
    const user = useUserSearchResultLogic(result)

    return (
        <Link className="flex w-full justify-between" to={result.link}>
            <div className="mr-auto my-auto">
                <p className="font-sans text-sm">
                    {user.name} {user.surname}
                </p>
                {user.group && (
                    <p className="font-sans mt-1 italic text-xs text-gray-slightlydark">
                        {user.group.name}
                    </p>
                )}
            </div>
            <div className="ml-auto my-auto">
                <ChevronRight />
            </div>
        </Link>
    )
}

export default UserSearchResult
