import { useCallback } from "react"
import { RouteComponentProps } from "@reach/router"

export type PlayProps = RouteComponentProps

export const usePlayLogic = (navigate: PlayProps["navigate"]) => {
    const onReceivedGame = useCallback(
        (gameId: number) => {
            if (!navigate) {
                return
            }

            navigate(`./game/${gameId}`)
        },
        [navigate]
    )

    return { onReceivedGame }
}
