import hotkeys, { KeyHandler } from "hotkeys-js"
import { useCallback, useEffect } from "react"

type KeyOptions = {
    scope?: string
    keyup?: boolean | null
    keydown?: boolean | null
    splitKey?: string
}

export function useHotkey(keys: string, cb: KeyHandler, deps: any[] = []) {
    useHotkeyWithOptions(keys, cb, {}, deps)
}

export function useHotkeyWithOptions(
    keys: string,
    cb: KeyHandler,
    options: KeyOptions = {},
    deps: any[] = []
) {
    const memoizedCb = useCallback(cb, deps)

    useEffect(() => {
        hotkeys(keys, options, memoizedCb)

        const { scope } = options
        if (scope) {
            return () => hotkeys.unbind(keys, scope, memoizedCb)
        }

        return () => hotkeys.unbind(keys, memoizedCb)
    }, [keys, memoizedCb, options])
}
