import { useState, useContext, useCallback, useEffect } from "react"

import { RouteComponentProps } from "@reach/router"
import { TFunction } from "i18next"
import LoadingContext from "context/LoadingProvider"
import { userAdapter } from "global/ApiWorker/Api"
import { useApi } from "hooks/useApi"

export interface RestorePasswordRouteParams {
    token: string
}

export interface RestorePasswordProps
    extends RouteComponentProps<RestorePasswordRouteParams> { }

export const useRestorePasswordLogic = (
    t: TFunction,
    token: RestorePasswordProps["token"],
    navigate: RestorePasswordProps["navigate"]
) => {
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [canSubmit, setCanSubmit] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const { setSaved, setFailed } = useContext(LoadingContext)

    const tokenValid = useCallback(async () => {
        if (submitted) {
            return
        }

        if (!token) {
            return Promise.reject()
        }

        const tokenIsValid = await userAdapter.checkToken(token)

        if (!tokenIsValid) {
            setFailed({
                show: true,
                title: t("restore.password.failed.title", "Link ungültig!"),
                text: t(
                    "restore.password.failed.text",
                    "Der genutzte Link ist ungültig."
                ),
            })

            return false
        }

        return true
    }, [setFailed, submitted, token, t])

    const { data, isLoading } = useApi(tokenValid)

    useEffect(() => {
        if (
            newPassword.length >= 8 &&
            /\d/.test(newPassword) &&
            newPassword === confirmPassword
        ) {
            setCanSubmit(true)
        } else {
            setCanSubmit(false)
        }
    }, [newPassword, confirmPassword])

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async event => {
        event.preventDefault()

        if (!token) {
            return
        }

        if (
            !newPassword ||
            !confirmPassword ||
            newPassword !== confirmPassword
        ) {
            return
        }

        setSubmitted(true)

        const response = await userAdapter.restorePassword(
            newPassword,
            confirmPassword,
            token
        )

        if (response) {
            setSaved({
                show: false,
                title: t(
                    "restore.password.done.title",
                    "Kennwort erfolgreich gespeichert!"
                ),
                text: t(
                    "restore.password.done.text",
                    "Sie können sich nun mit Ihrem neuen Kennwort einloggen."
                ),
            })

            navigate && navigate("/")
        }
    }

    const backToLogin = useCallback(() => {
        navigate && navigate("/")
    }, [navigate])

    return {
        isLoading,
        data,
        handleSubmit,
        canSubmit,
        backToLogin,

        newPassword,
        setNewPassword,

        confirmPassword,
        setConfirmPassword
    }
}
