import React, {
    ReactNode,
    useMemo,
} from "react"

import { ReactComponent as Star } from "svg/star.svg"
import { ReactComponent as ReloadSign } from "svg/reload.svg"
import { ReactComponent as MessageCircleSign } from "svg/message-circle.svg"

import { useTranslation } from "react-i18next"
import Button from "global/Button/Button"

import { RateTopicProps, useRateTopicLogic } from "hooks/logic/Frontend/Play/GameEnd/useRateTopicLogic"
import PopdownManager from "global/Popdown/PopdownManager"
import PopdownReference from "global/Popdown/PopdownReference"
import PopdownNode from "global/Popdown/PopdownNode"

import Styles from "./RateTopic.module.css"

const RateTopic: React.FC<RateTopicProps> = ({
    category: category_id,
    onChange,
    onRatingSent,
}) => {
    const [t] = useTranslation()
    const {
        rating,
        feedback,
        sendFeedback,
        updateRating
    } = useRateTopicLogic(
        category_id,
        t,
        onChange,
        onRatingSent
    )

    const stars = useMemo(() => {
        const arr = new Array<ReactNode>(5)

        for (let i = 0; i < rating; i++) {
            arr[i] = (
                <Star
                    className="mr-4 text-primary stroke-current fill-current cursor-pointer"
                    onClick={updateRating(i)}
                    key={`rating-${i}`}
                />
            )
        }

        for (let i = rating; i < 5; i++) {
            arr[i] = (
                <Star
                    className="mr-4 text-gray-muted stroke-current hover:text-primary cursor-pointer"
                    onClick={updateRating(i)}
                    key={`rating-${i}`}
                />
            )
        }

        return arr
    }, [rating, updateRating])

    return <div className={Styles.rateTopicWrapper}>
        <div className="container mx-auto">
            <div className="flex items-center">
                <div className="hidden tablet:flex w-1/3">
                    <div className="flex flex-wrap flex-col laptop:flex-row">
                        <div className="flex mr-2 font-bold mb-2">
                            {t("game.end.done.rating.feedback.title", "Kurs bewerten")}:
                        </div>
                        <div className="flex">{stars}</div>
                    </div>
                </div>
                <div className="hidden tablet:flex w-1/3">
                    {rating > 0 && rating <= 4 && (
                        <div className="flex w-full items-center">
                            <textarea
                                className="input resize-none"
                                id="rate-topic-feedback"
                                name="feedback"
                                placeholder={t("game.end.done.rating.feedback.placeholder", "Geben Sie Feedback ... (Optional)")}
                                ref={feedback}
                            />
                        </div>
                    )}
                </div>

                <div className="flex w-full tablet:w-1/3 justify-end items-center">
                    <div className="mr-auto tablet:hidden">
                        <PopdownManager>
                            <PopdownReference>
                                {({ togglePopdown, open }) => ({ ref }) => (
                                    <button
                                        ref={ref}
                                        onClick={togglePopdown}
                                        className={`flex text-primary p-2 rounded-xl font-bold uppercase ${open ? "bg-secondary" : ""}`}
                                    >
                                        <MessageCircleSign className="text-primary stroke-current" />
                                    </button>
                                )}
                            </PopdownReference>
                            <PopdownNode placement="top">
                                {({ togglePopdown }) => ({
                                    ref,
                                    style,
                                    placement,
                                    arrowProps,
                                }) => {
                                    return (
                                        <div
                                            ref={ref}
                                            className="w-full p-10 bg-white z-20 border-t border-b border-gray-muted"
                                            style={{
                                                ...style,
                                                top: "-19px",
                                                left: "-5px"
                                            }}
                                        >
                                            <div className="">
                                                <div className="flex flex-wrap flex-col mb-4">
                                                    <div className="flex mx-auto font-bold mb-4">
                                                        {t("game.end.done.rating.feedback.title", "Kurs bewerten")}:
                                                    </div>
                                                    <div className="flex mx-auto">{stars}</div>
                                                </div>
                                                <div className="flex flex-row">
                                                    <div className={`flex w-full items-center ${(rating > 0 && rating <= 4) ? "" : "opacity-0 pointer-events-none"}`}>
                                                        <textarea
                                                            className="input resize-none"
                                                            id="rate-topic-feedback"
                                                            name="feedback"
                                                            placeholder={t("game.end.done.rating.feedback.placeholder", "Geben Sie Feedback ... (Optional)")}
                                                            ref={feedback}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }}
                            </PopdownNode>
                        </PopdownManager>
                    </div>
                    <Button
                        onClick={sendFeedback(true)}
                        appearance="secondary"
                        >
                        <ReloadSign className="stroke-current mr-1" />
                        {t("game.end.restart-button", "Neu Starten")}
                    </Button>
                    <div className="w-4" />
                    <Button
                        onClick={sendFeedback()}
                        appearance="primary"
                        className="px-8"
                        >
                        {t("game.end.done-button", "Fertig")}
                    </Button>
                </div>
            </div>
        </div>
    </div>
}

export default RateTopic
