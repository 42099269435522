import React from "react"

import { useTranslation } from "react-i18next"

import PopdownManager from "global/Popdown/PopdownManager"
import PopdownReference from "global/Popdown/PopdownReference"
import PopdownNode from "global/Popdown/PopdownNode"

import { ReactComponent as PresentSign } from "svg/present.svg"

const JokerPopup: React.FC = ({ children }) => {
    const [t] = useTranslation()

    return (
        <>
            <PopdownManager>
                <PopdownReference>
                    {({ togglePopdown, open }) => ({ ref }) => (
                        <button
                            ref={ref}
                            onClick={togglePopdown}
                            className={`flex text-primary py-2 px-4 rounded-xl font-bold uppercase ${open ? "bg-secondary" : ""}`}
                        >
                            <PresentSign className="stroke-current mr-2" />{t("game.joker.title", "Joker")}
                        </button>
                    )}
                </PopdownReference>
                <PopdownNode placement="top">
                    {({ togglePopdown }) => ({
                        ref,
                        style,
                        placement,
                        arrowProps,
                    }) => {
                        return (
                            <div
                                ref={ref}
                                className="flex w-full py-4 px-6 bg-white z-20 border-t border-gray-muted items-center"
                                style={{
                                    ...style,
                                    top: "-16px",
                                    left: "-4px",
                                    height: "183px"
                                }}
                            >
                                {children}
                            </div>
                        )
                    }}
                </PopdownNode>
            </PopdownManager>
        </>
    )
}

export default JokerPopup
