import React from "react"

import { useContextLoadingLogic } from "hooks/logic/useContextLoadingLogic"

const ContextLoading: React.FC = () => {
    useContextLoadingLogic()

    return null
}

export default ContextLoading
