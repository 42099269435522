import { useState, useCallback } from "react"

export default function useStatefulApi<T, I>(
    makeRequest: (value: I) => Promise<T>,
    defaultValue: T | null = null
) {
    const [data, setData] = useState<T | typeof defaultValue>(defaultValue)
    const [isLoading, setIsLoading] = useState(false)

    const fetchData = useCallback(
        async (value?: I) => {
            if (!value) {
                setData(defaultValue)

                return
            }

            setIsLoading(true)

            const data = await makeRequest(value)

            setIsLoading(false)

            setData(data || defaultValue)
        },
        [setIsLoading, makeRequest, defaultValue]
    )

    return {
        data,
        isLoading,
        fetchData,
    }
}
