import React from "react"
import { Link, LinkGetProps } from "@reach/router"

import Styles from "./HeaderNavigationLink.module.css"

import { HeaderNavigationLinkProps } from "hooks/logic/Header/useHeaderNavigationLinkLogic"

const isActive = (classes: string[], exact: boolean) => ({
    isCurrent,
    isPartiallyCurrent,
}: LinkGetProps) => {
    const classNames = [...classes]

    if (isCurrent || (!exact && isPartiallyCurrent)) {
        classNames.push(Styles.active)
    }

    return { className: classNames.join(" ") }
}

const HeaderNavigationLink = React.memo<HeaderNavigationLinkProps>(({
    text,
    appearance,
    to,
    exact,
    children
}) => {
    const classes: string[] = [Styles.link]

    if (appearance) {
        classes.push(Styles[appearance])
    }

    return (
        <Link
            to={to}
            getProps={isActive(classes, !!exact)}
        >
            {children}
            {text}
        </Link>
    )
})

export default HeaderNavigationLink
