import React from "react"

import { animated, useTransition } from "react-spring"

import { useLoadingPopupLogic } from "hooks/logic/Popups/useLoadingPopupLogic"
import Loading from "global/Loading/Loading"
import Styles from "./LoadingPopup.module.css"

const LoadingPopup: React.FC = () => {
    const { loading } = useLoadingPopupLogic()

    const transitions = useTransition(loading, null, {
        initial: { transform: "translate3d(0, 0px, 0)" },
        from: { transform: "translate3d(0, 120px, 0)" },
        enter: { transform: "translate3d(0, 0px, 0)" },
        leave: { transform: "translate3d(0, 120px, 0)" },
    })

    return (
        <>
            {transitions.map(
                ({ item, key, props }) =>
                    item && (
                        <animated.div
                            className={Styles.popup}
                            style={props}
                            key={key}
                        >
                            <Loading />
                        </animated.div>
                    )
            )}
        </>
    )
}

export default LoadingPopup
