import { useCallback, useContext, useEffect, useMemo, useState } from "react"

import debugLib from "debug"
import useThrottled from "hooks/useThottled"
import ApiContext from "context/LoadingProvider"
import { DateTime } from "luxon"
import useAbortable from "hooks/useAbortable"

type WithFetchedDate<T> = {
    data: T | null
    date?: string
    isOptimistic: boolean
}

export interface UseApiProps<T> {
    onCancel?: Function
    initialValue?: T
    name?: string
    throttle?: number
    cacheKey?: string
}

export interface UseApiState<T> {
    data: T | null
    error: any
    isLoading: boolean
    isRefetching: boolean
    setOptimisticData: (data: T) => void
    run: Function
}

export type PromiseFn<T> = (...args: any[]) => Promise<T>

export function useApi<T>(
    promiseFn: PromiseFn<T>,
    props: UseApiProps<T> = {}
): UseApiState<T> {
    const { setLoading: setGlobalLoading } = useContext(ApiContext)

    const debugName = useMemo(() => {
        let debugName = "quadio:hooks:api"
        if (props.name) {
            debugName += ":" + props.name
        }

        return debugName
    }, [props])

    const { throttle: delay = 0 } = props

    const debug = useCallback(debugLib(debugName), [debugName])

    const defaultData = {
        data: props.initialValue || null,
        isOptimistic: false,
    }

    // TODO: actually use caching
    const cacheKey: string | undefined = undefined

    const [cache, setCache] = useState<{
        [key: string]: WithFetchedDate<T>
    }>({})
    const [data, setData] = useState<WithFetchedDate<T>>(defaultData)
    const [error, setError] = useState<Error | null>(null)
    const [isRefetching, setIsRefetching] = useState(true)

    useEffect(() => {
        debug("promise fn updated")
    }, [debug, promiseFn])

    const abortablePromiseFn = useCallback(useAbortable(promiseFn), [promiseFn])

    useEffect(() => {
        debug("abortable promise fn updated")
    }, [debug, abortablePromiseFn])

    const getData = useCallback(() => {
        if (cacheKey && cacheKey !== "default-cache-key" && cache[cacheKey]) {
            debug("cache is not empty - will not refetch")
            return
        }

        setIsRefetching(true)

        debug("fetching data")

        return abortablePromiseFn()
            .then(fetchedData => {
                debug("data fetched successfully", fetchedData)

                const result: WithFetchedDate<T> = {
                    data: fetchedData,
                    date: DateTime.local().toISO(),
                    isOptimistic: false,
                }

                if (cacheKey) {
                    setCache({
                        ...cache,
                        [cacheKey]: result,
                    })
                } else {
                    setData(result)
                }

                setIsRefetching(false)
            })
            .catch(requestError => {
                setGlobalLoading(false)
                if (!requestError) {
                    debug("unkown error")
                    return
                }

                if (requestError.name === "AbortError") {
                    debug("data fetch was aborted")
                    return
                }

                debug("data fetch threw exception", requestError.name)
                setError(requestError)
                setIsRefetching(false)
                throw requestError
            })
    }, [cacheKey, cache, debug, abortablePromiseFn, setGlobalLoading])

    const throttledGetData = useThrottled(getData, delay)

    useEffect(() => {
        debug("throttledGetData changed. triggering update")
        throttledGetData()
    }, [throttledGetData, debug])

    useEffect(() => {
        if (!cacheKey) {
            return
        }

        const cachedData = cache[cacheKey]

        if (!cache[cacheKey]) {
            debug(
                `not checking cache age - cache is empty for key ${cacheKey}`,
                cachedData
            )
            return
        }

        if (!cachedData.date) {
            debug(
                "not checking cache age - no data or no date given",
                cachedData
            )
            return
        }

        const then = DateTime.fromISO(cachedData.date)
        const { minutes: diff } = then.diffNow(["minutes"])

        if (diff >= -10) {
            debug(
                "serving cached data until refetch is done as cache is under 10 minutes old",
                diff
            )
            return
        }

        debug("resetting local cache as it got too old", diff)

        setIsRefetching(true)
        setCache({
            ...cache,
            [cacheKey]: {
                data: null,
                isOptimistic: false,
            },
        })
    }, [cache, cacheKey, setCache, debug])

    useEffect(() => {
        const tmp = cacheKey ? cache[cacheKey] : data
        if (!tmp || !tmp.isOptimistic) {
            return
        }

        debug("data was updated optimistically. refetching")

        getData()
    }, [cache, cacheKey, data, debug, getData])

    useEffect(() => {
        const tmp = cacheKey ? cache[cacheKey] : data

        if (tmp && tmp.data) {
            setIsRefetching(false)
        }
    }, [cache, cacheKey, data])

    useEffect(() => {
        debug("isRefetching", isRefetching)
        setGlobalLoading(isRefetching)
    }, [isRefetching, setGlobalLoading, debug])

    const setOptimisticData = useCallback(
        (newData: T) => {
            if (!cacheKey) {
                setData({
                    ...data,
                    data: newData,
                    isOptimistic: true,
                })
                return
            }

            setCache({
                ...cache,
                [cacheKey]: {
                    ...(cache[cacheKey] || {}),
                    data: newData,
                    isOptimistic: true,
                },
            })
        },
        [setCache, setData, data, cache, cacheKey]
    )

    useEffect(() => {
        if (!cacheKey) {
            return
        }

        debug(
            `cache key changed to ${cacheKey}. Updating data.`,
            cache[cacheKey]
        )
        setData(cache[cacheKey])
    }, [cache, cacheKey, debug, setData])

    const isLoading = (!data || !data.data) && isRefetching

    return {
        data: data ? data.data : null,
        isLoading,
        isRefetching,
        error,
        setOptimisticData,
        run: getData,
    }
}
