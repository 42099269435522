import React from "react"
import { useTranslation } from "react-i18next"

import { DisplayDurationProps, useDisplayDurationLogic } from "hooks/logic/useDisplayDurationLogic"

const DisplayDuration: React.FC<DisplayDurationProps> = ({
    seconds,
    short,
}) => {
    const [t] = useTranslation()
    const { duration } = useDisplayDurationLogic(seconds)

    let hoursText = ""

    if (duration.hours) {
        if (short) {
            hoursText = t("duration.short.hours", {
                defaultValue: "{{ count }} Std.",
                defaultValue_plural: "{{ count }} Std.",
                count: duration.hours,
            })
        } else {
            hoursText = t("duration.hours", {
                defaultValue: "{{ count }} Std.",
                defaultValue_plural: "{{ count }} Std.",
                count: duration.hours,
            })
        }
    }

    // chop minutes to 5-minute accuracy
    const minutes = duration.hours
        ? Math.floor(duration.minutes / 5) * 5
        : duration.minutes

    let minutesText = ""

    if (minutes) {
        if (short) {
            minutesText = t("duration.short.minutes", {
                defaultValue: "{{ count }} Min.",
                defaultValue_plural: "{{ count }} Min.",
                count: minutes,
            })
        } else {
            minutesText = t("duration.minutes", {
                defaultValue: "{{ count }} Min.",
                defaultValue_plural: "{{ count }} Min.",
                count: minutes,
            })
        }
    }

    let secondsText = ""

    if (!duration.hours && !duration.minutes) {
        if (short) {
            secondsText = t("duration.short.seconds", {
                defaultValue: "{{ count }} Sek.",
                defaultValue_plural: "{{ count }} Sek.",
                count: Math.ceil(duration.seconds),
            })
        } else {
            secondsText = t("duration.seconds", {
                defaultValue: "{{ count }} Sek.",
                defaultValue_plural: "{{ count }} Sek.",
                count: Math.ceil(duration.seconds),
            })
        }
    }

    const str = [hoursText, minutesText, secondsText].join(" ")

    return <>{str}</>
}

export default DisplayDuration
