import React from "react"
import { useTranslation } from "react-i18next"

import Button from "../Button/Button"
import { OverlayBoxWithBg } from "global/OverlayBox/OverlayBox"

import { ResetPasswordProps, useResetPasswordLogic } from "hooks/logic/ResetPassword/useResetPasswordLogic"

const ResetPassword: React.FC<ResetPasswordProps> = ({ navigate }) => {
    const [t] = useTranslation()
    const {
        handleSubmit,
        email,
        setEmail
    } = useResetPasswordLogic(t, navigate)

    const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = ({
        currentTarget: { value },
    }) => {
        setEmail(value)
    }

    const backToLogin = () => {
        navigate && navigate("/")
    }

    return (
        <OverlayBoxWithBg>
            <form onSubmit={handleSubmit}>
                <div className="mb-8">
                    <h2 className="mb-8 text-3xl">
                        {t("resetpassword.title", "Kennwort vergessen")}
                    </h2>
                    <input
                        className="input focus:outline-none focus:shadow-outline"
                        id="email"
                        type="email"
                        placeholder={t(
                            "resetpassword.form.placeholder.email",
                            "E-Mail Adresse"
                        )}
                        onChange={handleEmailChange}
                        value={email}
                        autoComplete={"email"}
                    />
                </div>
                <hr className="text-gray"></hr>
                <div className="mb-2 mt-8">
                    <Button
                        type="submit"
                        className="w-full justify-center mobile:justify-start"
                        appearance="primary"
                    >
                        {t("resetpassword.button.reset", "Einloggen")}
                    </Button>
                </div>
                <div className="mb-6 px-6">
                    <Button
                        onClick={backToLogin}
                        className="w-full justify-center mobile:justify-start"
                        appearance="transparent"
                    >
                        {t("resetpassword.button.login", "Zum Login")}
                    </Button>
                </div>
            </form>
        </OverlayBoxWithBg>
    )
}

export default ResetPassword
