export interface NotificationLinkProps {
    link?: {
        link: string
        text: string
    }
}

export const useNotificationLinkLogic = (data: NotificationLinkProps["link"]) => {
    if (!data) {
        return {
            link: null,
            text: null
        }
    }

    const { link, text } = data

    return {
        link,
        text
    }
}
