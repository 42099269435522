import React from "react"

import { useTranslation } from "react-i18next"
import { UserModel } from "@interweberde/prima-core"

import { ReactComponent as MenuDots } from "svg/menu-dots.svg"

import Styles from "./DotNavigation.module.css"
import Caret from "global/Dropdown/Caret"
import PopdownManager from "global/Popdown/PopdownManager"
import PopdownReference from "global/Popdown/PopdownReference"
import PopdownNode from "global/Popdown/PopdownNode"
import ButtonStyleLink from "global/Button/ButtonStyleLink"
import Button from "global/Button/Button"

const DotNavigation: React.FC<{
    user: UserModel
    backend?: boolean
    logout: () => void
}> = ({ backend, user, logout }) => {
    const [t] = useTranslation()

    return (
        <div className={Styles.dotNavigationWrap}>
            <PopdownManager>
                <PopdownReference>
                    {({ togglePopdown }) => ({ ref }) => (
                        <button
                            ref={ref}
                            className={
                                backend
                                    ? Styles.dotNavigationTogglerBackend
                                    : Styles.dotNavigationToggler
                            }
                            onClick={togglePopdown}
                        >
                            <MenuDots className={backend ? Styles.iconBackend : Styles.icon} />
                        </button>
                    )}
                </PopdownReference>
                <PopdownNode>
                    {({ togglePopdown }) => ({
                        ref,
                        style,
                        placement,
                        arrowProps,
                    }) => {
                        return (
                            <div
                                ref={ref}
                                className="mt-8 right-0 z-20"
                                style={{
                                    width: "235px",
                                    ...style,
                                }}
                            >
                                <ul className={`${Styles.dotNavigation} w-full`}>
                                    {user.is_admin && !backend && (
                                        <li>
                                            <ButtonStyleLink
                                                appearance="headerDropdown"
                                                to="/backend"
                                            >
                                                {t("header.frontend.to_backend", "Zum Admin-Bereich")}
                                            </ButtonStyleLink>
                                        </li>
                                    )}
                                    {user.is_admin && backend && (
                                        <li>
                                            <ButtonStyleLink
                                                appearance="headerDropdown"
                                                to="/"
                                            >
                                                {t("header.backend.to_frontend", "Zum Spiel-Bereich")}
                                            </ButtonStyleLink>
                                        </li>
                                    )}
                                    <li>
                                        <ButtonStyleLink
                                            appearance="headerDropdown"
                                            to="/user/settings"
                                            onClick={togglePopdown}
                                        >
                                            {t(
                                                "header.dropdown.menu.settings",
                                                "Einstellungen"
                                            )}
                                        </ButtonStyleLink>
                                    </li>
                                    <li>
                                        <Button
                                            appearance="logoutLink"
                                            onClick={logout}
                                        >
                                            {t(
                                                "header.dropdown.menu.logout",
                                                "Logout"
                                            )}
                                        </Button>
                                    </li>
                                </ul>

                                <Caret
                                    className="text-white"
                                    placement={placement}
                                    ref={arrowProps.ref}
                                    style={arrowProps.style}
                                />
                            </div>
                        )
                    }}
                </PopdownNode>
            </PopdownManager>
        </div>
    )
}

export default DotNavigation
