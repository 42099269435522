import { TFunction } from "i18next"
import { useState, useContext } from "react"
import LoadingContext from "context/LoadingProvider"
import { authAdapter } from "global/ApiWorker/Api"

export interface LoginProps { }

const redirectToClassic = (username: string, password: string) => {
    const form = document.createElement("form")
    form.hidden = true
    form.action = "https://rehavista.quadio.de"
    form.method = "POST"

    const userInput = document.createElement("input")
    userInput.type = "hidden"
    userInput.name = "data[User][username]"
    userInput.value = username

    form.appendChild(userInput)

    const passwordInput = document.createElement("input")
    passwordInput.type = "hidden"
    passwordInput.name = "data[User][password]"
    passwordInput.value = password

    form.appendChild(passwordInput)

    document.body.append(form)

    form.submit()
}

export const useLoginLogic = (t: TFunction) => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const { setSaved, setFailed } = useContext(LoadingContext)

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async event => {
        event.preventDefault()

        const result = await authAdapter.login(username, password)

        if (!result) {
            if (username.match(/(rehavista\.de|logbuk.de)$/)) {
                redirectToClassic(username, password)

                return
            }

            setFailed({
                show: true,
                title: t("login.fail.title", "Login fehlgeschlagen"),
                text: t("login.fail.text", "Logindaten nicht korrekt!"),
            })
            return
        }

        setSaved({
            show: true,
            title: t("login.success.title", "Eingeloggt"),
            text: t("login.success.text", "Sie wurden erfolgreich eingeloggt!"),
        })
    }

    return {
        handleSubmit,

        username,
        setUsername,

        password,
        setPassword
    }
}
