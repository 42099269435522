import React from "react"
import Route from "../../global/Route"
import Overview from "./Overview/Overview"
import AccessibilityRouter from "global/AccessibilityRouter"

const Statistics = React.lazy(() => import("./Statistics/Statistics"))
const Users = React.lazy(() => import("./Users/Users"))
const Topics = React.lazy(() => import("./Topics/Topics"))
const Subscription = React.lazy(() => import("./Subscription/Subscription"))

const Backend: React.FC = () => {
    return (
        <AccessibilityRouter>
            <Route path="/" component={Overview} />
            <Route path="statistics/*" component={Statistics} />
            <Route path="users/*" component={Users} />
            <Route path="topics/*" component={Topics} />
            <Route path="subscription/*" component={Subscription} />
        </AccessibilityRouter>
    )
}

export default Backend
