import React, { Suspense } from "react"
import { useTranslation } from "react-i18next"

import Route from "global/Route"
import ContextLoading from "global/ContextLoading"
import { UserSettingsProvider } from "context/UserSettingsContext"
import AccessibilityRouter from "global/AccessibilityRouter"

const OverviewStatistics = React.lazy(() =>
    import("pages/Backend/Overview/OverviewStatistics")
)

const Dashboard: React.FC = () => {
    const [t] = useTranslation()

    return <div className="container mx-auto mt-8 tablet:mt-14 mb-8">
        <h1 className="flex w-full mb-8 tablet:mb-12">
            {t("backend.overview.title", "Übersicht")}
        </h1>

        <Suspense fallback={<ContextLoading />}>
            <UserSettingsProvider>
                <AccessibilityRouter>
                    <Route
                        path="/"
                        component={OverviewStatistics}
                    />
                </AccessibilityRouter>
            </UserSettingsProvider>
        </Suspense>
    </div>
}

export default Dashboard
