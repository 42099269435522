import React from "react"
import { Reference } from "react-popper"

import { PopdownReferenceProps, usePopdownReferenceLogic } from "hooks/logic/Popdown/usePopdownReferenceLogic"

const PopdownReference: React.FC<PopdownReferenceProps> = ({ children }) => {
    const {
        setReferenceNode,
        childFn
    } = usePopdownReferenceLogic(children)

    return <Reference innerRef={setReferenceNode}>{childFn}</Reference>
}

export default PopdownReference
