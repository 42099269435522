import React from "react"
import ReactDOM from "react-dom"

import "./index.css"

import "./i18n"
import App from "./App"

import * as serviceWorker from "./serviceWorker"

// test reacts concurrent mode
// @ts-ignore
// ReactDOM.unstable_createRoot(document.getElementById('root')).render(<App />)

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
