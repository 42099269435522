import React, { Suspense } from "react"
import Route from "../../global/Route"
import ContextLoading from "global/ContextLoading"
import Play from "pages/Frontend/Dashboard/Play/Play"
import { NotFound } from "pages/General/Error"
import AccessibilityRouter from "global/AccessibilityRouter"

const Dashboard = React.lazy(() => import("./Dashboard/Dashboard"))
const Leaderboards = React.lazy(() => import("./Leaderboards/Leaderboards"))
const Help = React.lazy(() => import("./Help/Help"))
const User = React.lazy(() => import("./user/User"))

const Frontend: React.FC = () => {
    return (
        <>
            <main className="pt-8 tablet:pt-14 pb-10">
                <Suspense fallback={<ContextLoading />}>
                    <AccessibilityRouter>
                        <Route path="/" component={Dashboard} />
                        <Play path="play/*" />
                        <Route path="leaderboards" component={Leaderboards} />
                        <Route path="help" component={Help} />
                        <Route path="user/*" component={User} />
                        <NotFound default />
                    </AccessibilityRouter>
                </Suspense>
            </main>
        </>
    )
}

export default Frontend
