import React, { ComponentType, useContext } from "react"
import { RouteComponentProps } from "@reach/router"
import AuthContext from "context/AuthProvider"
import { LoginOverlay } from "./LoginOverlay/Login"
import LoadingContext from "context/LoadingProvider"

interface RouteProps extends RouteComponentProps {
    authenticated?: boolean
    component: ComponentType
}

const Route: React.FC<RouteProps> = ({
    authenticated,
    component: Component,
}: RouteProps) => {
    const { authentication } = useContext(AuthContext)
    const { setLoading } = useContext(LoadingContext)

    // TODO: check if user is allowed to see the route!

    if (authenticated) {
        if (authentication === null) {
            setLoading(true)
            return null
        }

        setLoading(false)

        if (!authentication) {
            return <LoginOverlay />
        }
    }

    return <Component />
}

export default Route
