import React, { PropsWithChildren } from "react"
import Style from "./Button.module.css"
import { Link, LinkProps } from "@reach/router"

interface ButtonProps<TState> extends Omit<LinkProps<TState>, "ref"> {
    appearance?: string
    rounded?: boolean
}

function ButtonStyleLink<TState>({
    children,
    appearance,
    className,
    rounded = true,
    ...linkProps
}: PropsWithChildren<ButtonProps<TState>>) {
    const classNames = [Style.button, "p-4"]

    if (appearance) {
        classNames.push(Style[appearance])
    }

    if (className) {
        classNames.push(className)
    }

    if (rounded) {
        classNames.push(Style.rounded)
    }

    return (
        <Link {...linkProps} className={classNames.join(" ").trim()}>
            {children}
        </Link>
    )
}

export default ButtonStyleLink
