import { transferHandlers } from "comlink"
import { DateObject, DateTime } from "luxon"
import get from "lodash/get"
import set from "lodash/set"
import cloneDeep from "lodash/cloneDeep"

const iterate = (
    obj: any,
    handleKeyCb: (keyList: string[], value: any) => boolean,
    keys?: string[]
) => {
    Object.entries(obj).forEach(([key, value]) => {
        if (!value || typeof value !== "object") {
            return
        }

        const keyList = [...(keys || []), key]

        if (!handleKeyCb(keyList, value)) {
            return
        }

        iterate(value, handleKeyCb, keyList)
    })
}

const deserializeObjectWithDateTime = (obj: any) => {
    const dateProps: string[] = obj.__dateProps

    dateProps.forEach(key => {
        const val: DateObject = get(obj, key)
        set(obj, key, DateTime.fromObject(val))
    })

    delete obj.__dateProps

    return obj
}

const serializeObjectWithDateTime = (obj: any): [any, Transferable[]] => {
    const dateProps: string[] = []

    // clone object so we do not mutate it
    obj = cloneDeep(obj)

    iterate(obj, (keyList, value) => {
        if (!DateTime.isDateTime(value)) {
            return true
        }

        const key = keyList.join(".")
        dateProps.push(key)

        return false
    })

    dateProps.forEach(key => {
        const val: any = get(obj, key)
        set(obj, key, val.c)
    })

    obj.__dateProps = dateProps

    return obj
}

const handlers: typeof transferHandlers = new Map()

handlers.set("model", {
    canHandle(obj: any): boolean {
        if (!obj) {
            return false
        }

        return obj instanceof Object && obj.constructor.name === "Object"
    },
    deserialize(obj: any): any {
        return deserializeObjectWithDateTime(obj)
    },
    serialize(obj: any): [any, Transferable[]] {
        return [serializeObjectWithDateTime(obj), []]
    },
})

handlers.set("array", {
    canHandle(arr: any): boolean {
        if (!arr) {
            return false
        }

        return arr instanceof Array && arr.constructor.name === "Array"
    },
    deserialize(arr: any[]): any[] {
        return arr.map(obj => {
            if (!obj || !(obj instanceof Object)) {
                return obj
            }

            return deserializeObjectWithDateTime(obj)
        })
    },
    serialize(arr: any[]): [any[], Transferable[]] {
        // TODO: also handle nested arrays
        arr = arr.map(obj => {
            if (!obj || !(obj instanceof Object)) {
                return obj
            }

            return serializeObjectWithDateTime(obj)
        })

        return [arr, []]
    },
})

handlers.set("datetime", {
    canHandle(obj: any): boolean {
        return DateTime.isDateTime(obj)
    },
    deserialize(obj: any): any {
        return DateTime.fromObject(obj)
    },
    serialize(obj: any): [any, Transferable[]] {
        return [obj.c, []]
    },
})

handlers.set("progressEvent", {
    canHandle(obj: any): boolean {
        return obj instanceof ProgressEvent
    },
    deserialize(obj: any): any {
        return obj
    },
    serialize(obj: ProgressEvent): [any, Transferable[]] {
        return [
            {
                lengthComputable: obj.lengthComputable,
                loaded: obj.loaded,
                total: obj.total,
            },
            [],
        ]
    },
})

export default handlers
