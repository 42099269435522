import React, { Dispatch, SetStateAction, useCallback, useContext } from "react"
import { Reference } from "react-popper"
import { PopdownManagerContext } from "hooks/logic/Popdown/usePopdownManagerLogic"

export type ReferenceProps = React.ComponentProps<typeof Reference>

export interface PopdownReferenceChildrenArgs {
    togglePopdown: () => void
    setOpen: Dispatch<SetStateAction<boolean>>
    open: boolean
}

export interface PopdownReferenceProps
    extends Omit<ReferenceProps, "children"> {
    children: (
        props: PopdownReferenceChildrenArgs
    ) => ReferenceProps["children"]
}

export const usePopdownReferenceLogic = (children: PopdownReferenceProps["children"]) => {
    const { setReferenceNode, togglePopdown, setOpen, open } = useContext(
        PopdownManagerContext
    )

    const childFn = useCallback(children({ togglePopdown, setOpen, open }), [
        children,
        togglePopdown,
        open,
    ])

    return {
        setReferenceNode,
        childFn,
        open
    }
}
