import React, { useCallback, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import Button from "global/Button/Button"

import { ReactComponent as EyeClose } from "svg/eye-closed.svg"
import { ReactComponent as EyeOpen } from "svg/eye-open.svg"

import { SignUpUserFormProps, useSignUpUserFormLogic } from "hooks/logic/SignUp/useSignUpUserFormLogic"
import { usePasswordStrengthLogic } from "hooks/logic/PasswordStrength/usePasswordStrengthLogic"
import Progress from "global/Progress/Progress"

import Styles from "../../SignUp.module.css"
import SignUpStep from "global/SignUp/SignUpStep/SignUpStep"
import Checkbox from "global/Forms/Checkbox"

const SignUpUserForm: React.FC<SignUpUserFormProps> = ({ navigate }) => {
    const [t] = useTranslation()
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [canSubmit, setCanSubmit] = useState<boolean>(false)

    const {
        handleSubmit,
        getToken,

        firstname,
        setFirstname,

        surname,
        setSurname,

        email,
        setEmail,

        mandantor,
        setMandantor,

        password,
        setPassword,

        agbAccepted,
        setAgbAccepted,
    } = useSignUpUserFormLogic(t)

    const updateTerms = useCallback(() => {
        setAgbAccepted(agbAccepted => !agbAccepted)
    }, [setAgbAccepted])

    const { strength } = usePasswordStrengthLogic(password)

    const handleFirstnameChange: React.ChangeEventHandler<HTMLInputElement> = ({
        currentTarget: { value },
    }) => {
        setFirstname(value)
    }

    const handleSurnameChange: React.ChangeEventHandler<HTMLInputElement> = ({
        currentTarget: { value },
    }) => {
        setSurname(value)
    }

    const handleMandantorChange: React.ChangeEventHandler<HTMLInputElement> = ({
        currentTarget: { value },
    }) => {
        setMandantor(value)
    }

    const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = ({
        currentTarget: { value },
    }) => {
        setEmail(value)
    }

    const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = ({
        currentTarget: { value },
    }) => {
        setPassword(value)
    }

    const onSubmit = useCallback(async (e) => {
        handleSubmit(e)

        const token  = await getToken()

        if (!token) {
            return
        }

        navigate && navigate(`departments/${token}`)
    }, [handleSubmit, navigate, getToken])

    const toggleShowPassowrd = useCallback(() => {
        setShowPassword(showPassword => !showPassword)
    }, [setShowPassword])

    useEffect(() => {
        setCanSubmit(
            firstname.length > 0
            && surname.length > 0
            && email.length > 0
            && mandantor.length > 0
            && strength >= 2
            && agbAccepted
        )
    }, [agbAccepted, email, firstname, mandantor, strength, surname])

    return (
        <form className="flex flex-wrap" onSubmit={onSubmit}>
            <div className="flex w-full flex-wrap mb-6">
                <h2 className="flex w-full text-2xl justify-center">{t("signup.title", "Kostenlosen Test-Zeitraum starten")}</h2>
                <SignUpStep step={1} max={3} />
                <div className="flex w-1/2 pr-3">
                    <input
                        className={`${Styles.input}`}
                        id="firstname"
                        type="text"
                        placeholder={t(
                            "signup.form.placeholder.firstname",
                            "Name*"
                        )}
                        onChange={handleFirstnameChange}
                        value={firstname}
                        autoComplete={"firstname"}
                    />
                </div>
                <div className="flex w-1/2 pl-3">
                    <input
                        className={`${Styles.input}`}
                        id="surname"
                        type="text"
                        placeholder={t(
                            "signup.form.placeholder.surname",
                            "Nachname*"
                        )}
                        onChange={handleSurnameChange}
                        value={surname}
                        autoComplete={"surname"}
                    />
                </div>
            </div>
            <div className="flex flex-wrap w-full mb-6">
                <input
                    className={`${Styles.input}`}
                    id="mandantor"
                    type="mandantor"
                    placeholder={t(
                        "signup.form.placeholder.mandantor",
                        "Unternehmen*"
                    )}
                    onChange={handleMandantorChange}
                    value={mandantor}
                />
            </div>
            <div className="flex flex-wrap w-full mb-6">
                <input
                    className={`${Styles.input}`}
                    id="email"
                    type="email"
                    placeholder={t(
                        "signup.form.placeholder.email",
                        "E-Mail Adresse*"
                    )}
                    onChange={handleEmailChange}
                    value={email}
                />
            </div>
            <div className="flex flex-wrap w-full mb-4 relative">
                <input
                    className={`${Styles.input}`}
                    id="password"
                    type={showPassword ? "text" : "password"}
                    placeholder={t(
                        "signup.form.placeholder.password",
                        "Passwort*"
                    )}
                    onChange={handlePasswordChange}
                    value={password}
                    autoComplete="new-password"
                />
                {showPassword
                    ? <EyeOpen className="absolute cursor-pointer right-0 text-gray stroke-current fill-current mr-2 mt-3" onClick={toggleShowPassowrd} />
                    : <EyeClose className="absolute cursor-pointer right-0 text-gray stroke-current fill-current mr-2 mt-6" onClick={toggleShowPassowrd} />
                }
            </div>
            <div className="flex w-full h-2 mb-4">
                <Progress
                    value={strength}
                    max={4}
                    appearance={
                        strength < 2
                            ? "red" : strength < 3
                                ? "yellow" : strength < 4
                                    ? "greenLight"
                                    : "green"
                    }
                />
            </div>

            <div className="flex flex-wrap w-full mb-8">
                <p className="text-gray text-xs">{t("signup.form.hint.password", "Stellen Sie sicher, dass Ihr Passwort mindestens 8 Zeichen beinhaltet und ein Sonderzeichen, sowie eine Zahl enthält.")}</p>
            </div>

            <div className="flex flex-row w-full mb-10">
                <Checkbox
                    defaultChecked={agbAccepted}
                    name="certificates"
                    onChange={updateTerms}
                    className="my-auto"
                >
                </Checkbox>
                <p className="ml-2 text-14">
                    {t("signup.form.agb.accept", "Ich akzeptiere die")}&nbsp;<a className="text-primary hover:text-primary-hover font-bold" target="_blank" href="agb">{t("signup.form.agb.link", "Allgemeinen Geschäftsbedingungen")}</a>
                </p>
            </div>

            <div className="flex flex-wrap w-full">
                <Button
                    type="submit"
                    className="w-full justify-center mb-6"
                    appearance="primary"
                    disabled={!canSubmit}
                    rounded={false}
                >
                    {t("signup.button.sign_up", "Registrieren")}
                </Button>
                <p className="text-gray mb-4">*{t("global.required", "erforderlich")}</p>
                <p className="text-gray text-xs">{t("signup.form.hint.security", "By creating an account, you agree to the Terms of Service. For more information about GitHub's privacy practices, see the GitHub Privacy Statement. We'll occasionally send you account-related emails.")}</p>
            </div>
        </form>
    )
}

export default SignUpUserForm
