import { useContext, useMemo, useCallback } from "react"

import { TFunction } from "i18next"

import LoadingContext from "context/LoadingProvider"

export interface FailNotification {
    show: boolean
    title?: string
    text?: string
}

export interface FailPopupProps extends React.HTMLProps<HTMLDivElement> {
    appearance?: string
}

export const useFailPopupLogic = (t: TFunction) => {
    const { failed, setFailed } = useContext(LoadingContext)

    const title = t("popups.failed.title", "Fehlgeschlagen!")
    const text = t(
        "popups.failed.text",
        "Ihre Änderungen konnten nicht gespeichert werden!"
    )

    const failDisplay = useMemo(() => {
        return {
            show: failed.show,
            title: failed.title || title,
            text: failed.text || text,
        }
    }, [failed.show, failed.text, failed.title, text, title])

    const closeModal = useCallback(() => {
        setFailed({
            show: false,
            title: failed.title || title,
            text: failed.text || text,
        })
    }, [failed.text, failed.title, setFailed, text, title])

    return {
        failDisplay,
        closeModal,
        failed
    }
}
