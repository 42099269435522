import { useCallback, useEffect, useRef } from "react"

class AbortError implements Error {
    message: string
    name: string

    constructor(effectAborted: string) {
        this.name = "AbortError"
        this.message = effectAborted
    }
}

function useAbortable<T>(fn: () => Promise<T>): () => Promise<T> {
    const isMounted = useRef(true)

    const cb = useCallback(async () => {
        const promise = fn()

        try {
            await promise
        } catch (error) {
            // in case of empty promise rejection: pass through the empty error
            if (!error) {
                throw error
            }

            // handle axios' abort exception
            if (error.__CANCEL__) {
                throw new AbortError("effect-aborted")
            }

            throw error
        }

        if (!isMounted.current) {
            throw new AbortError("effect-aborted")
        }

        return promise
    }, [fn])

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    return cb
}

export default useAbortable
