import { UserModel } from "@interweberde/prima-core"
import { SearchResult } from "global/SearchService/SearchServiceAdapter"

interface ExtendedUserModel extends UserModel {
    group: any
}

export const useUserSearchResultLogic = (result: SearchResult) => {
    return result.payload as ExtendedUserModel
}
