import React from "react"
import Loading from "global/Loading/Loading"
import { Link } from "@reach/router"
import { useTranslation } from "react-i18next"
import Progress from "global/Progress/Progress"

import { ReactComponent as TimesSign } from "svg/times.svg"
import { ReactComponent as Checkmark } from "svg/checkmark-green.svg"
import { ReactComponent as CheckmarkSmile } from "svg/checkmark-smile.svg"
import Styles from "./GameEnd.module.css"

import GameEndGameStatistic from "./GameEndGameStatistic"

import RateTopic from "pages/Frontend/Dashboard/Play/GameEnd/RateTopic"

import { GameEndProps, useGameEndLogic } from "hooks/logic/Frontend/Play/GameEnd/useGameEndLogic"

const GameEnd: React.FC<GameEndProps> = ({ game }) => {
    const [t] = useTranslation()
    const {
        isLoading,
        data,
        onDoneRating,
        smile,
        onChangeRating
    } = useGameEndLogic(game)

    if (isLoading || data === null) {
        return <Loading />
    }

    const { game: gameStatistics, statistics: categoryStatistics } = data

    return <>
        <div className={`container content-center justify-center ${Styles.gameEndWrapper}`}>
            <div className="container flex align-content-center mx-auto">
                <Link to="/" className="ml-0 mr-auto">
                    <TimesSign />
                    <span className="sr-only">
                        {t("game.abort", { defaultValue: "Abbrechen" })}
                    </span>
                </Link>
                <div className="hidden mobile:flex h-2 w-2/3 my-auto mx-4">
                    <Progress max={100} value={100} />
                </div>
                <Link
                    to="/"
                    className="my-auto ml-auto flex-shrink-0 text-gray-muted"
                >
                    {t("game.end.done-progress", "Abgeschlossen")}
                </Link>
            </div>

            <div className="text-center w-full mt-10 mobile:mt-20 mx-auto">
                {!smile && <Checkmark className="m-auto" />}
                {smile && <CheckmarkSmile className="m-auto" />}
                <p className="w-full text-3xl text-center normal-case mt-4">
                    {t("game.end.title", {
                        defaultValue: "Durchgang beendet",
                    })}
                </p>

                <GameEndGameStatistic
                    gameStatistics={gameStatistics}
                    categoryStatistics={categoryStatistics}
                />
            </div>
        </div>

        <RateTopic
            category={gameStatistics.category_id}
            onChange={onChangeRating}
            onRatingSent={onDoneRating}
        />
    </>
}

export default GameEnd
