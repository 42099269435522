import React from "react"
import { Manager } from "react-popper"
import { PopdownManagerProps, usePopdownManagerLogic, PopdownManagerContext } from "hooks/logic/Popdown/usePopdownManagerLogic"

const PopdownManager: React.FC<PopdownManagerProps> = ({
    children,
    onToggle,
    defaultOpen = false,
}) => {
    const { providerValue } = usePopdownManagerLogic({ onToggle, defaultOpen })
    return (
        <Manager>
            <PopdownManagerContext.Provider value={providerValue}>
                {children}
            </PopdownManagerContext.Provider>
        </Manager>
    )
}

export default PopdownManager
