import React from "react"

import { Link } from "@reach/router"
import { useTranslation } from "react-i18next"

import HeaderNavigationLink from "./HeaderNavigationLink/HeaderNavigationLink"

import { ReactComponent as HeaderLogo } from "svg/header-logo.svg"
import { ReactComponent as HeaderLogoBackend } from "svg/header-logo-backend.svg"

import { ReactComponent as LearningSign } from "svg/learning-sign.svg"
import { ReactComponent as ColleaguesSign } from "svg/colleagues-sign.svg"
import { ReactComponent as MyStatisticsSign } from "svg/my-statistics-sign.svg"
import { ReactComponent as HelpSign } from "svg/help-sign.svg"
import { ReactComponent as LayerSign } from "svg/layer.svg"
import { ReactComponent as PlaySign } from "svg/play.svg"

import { ReactComponent as StatisticSign } from "svg/statistic.svg"
import { ReactComponent as UsersSign } from "svg/users.svg"
import { ReactComponent as TopicsSign } from "svg/topics.svg"

import Styles from "./Header.module.css"
import SearchField from "global/SearchField/SearchField"
import NotificationDropdown from "global/NotificationDropdown/NotificationDropdown"
import UserDropdown from "./UserDropdown/UserDropdown"
import BurgerMenu from "./BurgerMenu/BurgerMenu"
import DotNavigation from "./DotNavigation/DotNavigation"
import AccessibilityRouter from "global/AccessibilityRouter"

import { useHeaderLogic } from "hooks/logic/Header/useHeaderLogic"

export const ChildrenRoute: React.FC<{ path: string; render: React.FC }> = ({
    render: Render,
}) => {
    return <Render />
}

const Header: React.FC = () => {
    const [t] = useTranslation()
    const {
        authentication,
        user,
        logoutUser
    } = useHeaderLogic(t)

    if (!authentication || !user) {
        return null
    }

    return (
        <AccessibilityRouter className={Styles.headerWrap} primary={false}>
            <ChildrenRoute
                path="/*"
                render={() => {
                    return (
                        <div className={`header-container mx-auto ${Styles.header}`}>
                            <div className={Styles.headerLeft}>
                                <Link to="/" className={Styles.brand}>
                                    <HeaderLogo />
                                </Link>
                                <nav className={Styles.navigation}>
                                    <HeaderNavigationLink
                                        appearance="frontend"
                                        text={t("header.frontend.learn", "Lernen")}
                                        to="/"
                                        exact
                                    >
                                        <LearningSign className={Styles.headerIcon} />
                                    </HeaderNavigationLink>
                                    <HeaderNavigationLink
                                        appearance="frontend"
                                        text={t("header.frontend.colleagues", "Kollegen")}
                                        to="/leaderboards"
                                    >
                                        <ColleaguesSign className={Styles.headerIcon} />
                                    </HeaderNavigationLink>
                                    <HeaderNavigationLink
                                        appearance="frontend"
                                        text={t("header.frontend.my_statistic", "Meine Statistik")}
                                        to="/user/statistics"
                                    >
                                        <MyStatisticsSign className={Styles.headerIcon} />
                                    </HeaderNavigationLink>
                                    <HeaderNavigationLink
                                        appearance="frontend"
                                        text={t("header.frontend.help", "Hilfe")}
                                        to="/help"
                                    >
                                        <HelpSign className={Styles.headerIcon} />
                                    </HeaderNavigationLink>
                                </nav>
                            </div>
                            <div className={Styles.headerRight}>
                                {user.is_admin && (
                                    <div className="hidden laptop:flex">
                                        <Link
                                            to="/backend"
                                            className="flex my-auto text-primary rounded-full h-10 px-4 items-center hover:bg-gray-muted"
                                        >
                                            <LayerSign className={`${Styles.headerIcon} text-primary stroke-current`} />{t("header.frontend.to_backend", "Zum Admin-Bereich")}
                                        </Link>
                                    </div>
                                )}
                                <NotificationDropdown />
                                <UserDropdown
                                    user={user}
                                    logout={logoutUser}
                                />
                                <DotNavigation
                                    backend={false}
                                    user={user}
                                    logout={logoutUser}
                                />
                            </div>
                            <BurgerMenu
                                user={user}
                                logout={logoutUser}
                            />
                        </div>
                    )
                }}
            />
            <ChildrenRoute
                path="/backend/*"
                render={() => {
                    return (
                        <div className={Styles.headerBackend}>
                            <div className={`header-container mx-auto flex justify-between w-full`}>
                                <div className={Styles.headerLeft}>
                                    <Link to="/backend" className={Styles.brand}>
                                        <HeaderLogoBackend />
                                    </Link>
                                    <nav className={Styles.navigation}>
                                        <HeaderNavigationLink
                                            appearance="backend"
                                            text={t("header.backend.overview", "Übersicht")}
                                            to="./"
                                            exact
                                        >
                                            <LearningSign className={Styles.headerIcon} />
                                        </HeaderNavigationLink>
                                        <HeaderNavigationLink
                                            appearance="backend"
                                            text={t("header.backend.statistics", "Statistiken")}
                                            to="statistics"
                                        >
                                            <StatisticSign className={Styles.headerIcon} />
                                        </HeaderNavigationLink>
                                        <HeaderNavigationLink
                                            appearance="backend"
                                            text={t("header.backend.users", "Benutzer")}
                                            to="users"
                                        >
                                            <UsersSign className={Styles.headerIcon} />
                                        </HeaderNavigationLink>
                                        <HeaderNavigationLink
                                            appearance="backend"
                                            text={t("header.backend.topics", "Themen")}
                                            to="topics"
                                        >
                                            <TopicsSign className={Styles.headerIcon} />
                                        </HeaderNavigationLink>
                                        <HeaderNavigationLink
                                            appearance="backend"
                                            text={t("header.backend.subscription", "Abonnement")}
                                            to="subscription"
                                        >
                                            <TopicsSign className={Styles.headerIcon} />
                                        </HeaderNavigationLink>
                                    </nav>
                                </div>
                                <div className={Styles.headerRight}>
                                    {user.is_admin && (
                                        <div className="hidden laptop:flex mr-4">
                                            <Link
                                                to="/"
                                                className="flex my-auto text-primary rounded-full h-10 px-4 items-center hover:bg-gray-muted"
                                            >
                                                <PlaySign className={`${Styles.headerIcon} text-primary stroke-current`} />{t("header.backend.to_frontend", "Zum Spiel-Bereich")}
                                            </Link>
                                        </div>
                                    )}
                                    <div className="hidden mobile:flex">
                                        <SearchField
                                            mode="backend"
                                            placeholder={t("header.search.placeholder", "z.B. Datenschutz")}
                                        />
                                    </div>
                                    <UserDropdown
                                        backend={true}
                                        user={user}
                                        logout={logoutUser}
                                    />
                                    <DotNavigation
                                        backend={true}
                                        user={user}
                                        logout={logoutUser}
                                    />
                                </div>
                                <BurgerMenu
                                    backend={true}
                                    user={user}
                                    logout={logoutUser}
                                />
                            </div>
                        </div>
                    )
                }}
            />
        </AccessibilityRouter>
    )
}

export default Header
