import React from "react"

import NotificationDate from "global/NotificationDropdown/NotificationDate"
import NotificationLink from "global/NotificationDropdown/NotificationLink"
import { NotificationProps } from "hooks/logic/NotificationDropdown/useNotificationLogic"

const DefaultNotification: React.FC<NotificationProps> = ({ notification }) => {
    return (
        <>
            <div className="w-2/3">
                <p className="text-sm">{notification.text}</p>
                <p className="mt-2 text-sm text-blue font-bold">
                    <NotificationDate date={notification.created} />
                </p>
            </div>
            <NotificationLink link={notification.link} />
        </>
    )
}

export default DefaultNotification
