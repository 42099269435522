import React from "react"
import { useTranslation } from "react-i18next"

import Styles from "./SignUpStep.module.css"

export interface SignUpStepProps {
    step: number
    max: number
}

const SignUpStep: React.FC<SignUpStepProps> = ({step, max}) => {
    const [t] = useTranslation()

    const indicators = []
    for( let i = 1 ; i <= max; i++ ) {
        indicators.push(i === step ? true : false)
    }

    return (
        <div className="flex w-full items-center justify-center mt-6 mb-12">
            <p className="text-gray mr-2 text-14">
                {t("signup.step", "Schritt {{step}}/{{max}}", {
                    step: step,
                    max: max
                })}
            </p>
            <div className="flex felx-row">
                {indicators.map((item, key) => {
                    return <div
                        key={key}
                        className={item ? Styles.active : Styles.inactive}
                    />
                })}
            </div>
        </div>
    )
}

export default SignUpStep
