import React, { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"

import Button from "global/Button/Button"

import { ReactComponent as PlusCircleIcon } from "svg/plus-circle.svg"
import { useSignUpInvitesFormLogic, SignUpInvitesFormProps } from "hooks/logic/SignUp/useSignUpInvitesFormLogic"

import Styles from "./SignUpInvitesForm.module.css"

const SignUpInvitesForm: React.FC<SignUpInvitesFormProps> = ({ token, navigate }) => {
    const [t] = useTranslation()
    const {
        handleSubmit,
        skip,
        addEntry,
        updateInvite,
        updateDepartment,
        isTokenValid,

        invites
    } = useSignUpInvitesFormLogic(token, t)

    const handleInviteNameChange: React.ChangeEventHandler<HTMLInputElement> = ({
        currentTarget,
    }) => {
        const inviteId = currentTarget.getAttribute('invite-id')

        if (!inviteId) {
            return
        }

        const { value } = currentTarget
        updateInvite(inviteId, value)
    }

    const handleDepartmentChange: React.ChangeEventHandler<HTMLSelectElement> = ({
        currentTarget,
    }) => {
        const inviteId = currentTarget.getAttribute('invite-id')

        if (!inviteId) {
            return
        }

        const { value } = currentTarget
        updateDepartment(inviteId, parseInt(value))
    }

    const submitInvites = useCallback(() => {
        handleSubmit()

        isTokenValid()
    }, [handleSubmit, isTokenValid])

    useEffect(() => {
        if (!isTokenValid()) {
            navigate && navigate("/")
        }
    }, [navigate, token, isTokenValid])

    return (
        <div className="container mx-auto">
            <div className="flex flex-col w-1/2 mb-12 mx-auto">
                {invites.map((invite, key) => {
                    return (
                        <div
                            className="flex w-full mb-6"
                            key={invite.id}
                        >
                            <div className="flex w-2/3">
                                <input
                                    autoFocus={key === 0}
                                    tabIndex={invite.id}
                                    id={`email-${invite.id.toString()}`}
                                    invite-id={invite.id.toString()}
                                    className={Styles.inviteInput}
                                    type="text"
                                    value={invite.email}
                                    placeholder={t(
                                        "signup.invites.placeholder.email",
                                        "E-Mail Adresse"
                                    )}
                                    onChange={handleInviteNameChange}
                                    autoComplete="new-email"
                                />
                            </div>
                            <div className="flex w-1/3">
                                <select
                                    id={`department-${invite.id.toString()}`}
                                    invite-id={invite.id.toString()}
                                    className={`${Styles.departmentSelect}`}
                                    value={invite.department}
                                    placeholder={t(
                                        "signup.invites.placeholder.department",
                                        "Abteilung auswählen"
                                    )}
                                    onChange={handleDepartmentChange}
                                    autoComplete="new-department"
                                >
                                    <option value="select">{t(
                                        "signup.invites.default_department",
                                        "Nicht zugeordnet"
                                    )}</option>
                                </select>
                            </div>
                        </div>
                    )
                })}
                <div className="flex">
                    <Button
                        appearance="transparent"
                        className="mx-auto"
                        onClick={addEntry}
                    >
                        <PlusCircleIcon className="text-gray fill-current mr-3" />
                        {t("signup.invites.add_colleague", "Mehr hinzufügen")}
                    </Button>
                </div>
            </div>
            <div className="flex flex-col w-1/2 mb-12 mx-auto">
                <Button
                    appearance="primary"
                    className="justify-center mb-5"
                    onClick={submitInvites}
                    rounded={false}
                >
                    {t("signup.invites.invite", "Einladen")}
                </Button>
                <Button
                    appearance="outline"
                    className="justify-center"
                    onClick={skip}
                    rounded={false}
                >
                    {t("signup.invites.later", "Später einladen")}
                </Button>
            </div>
        </div>
    )
}

export default SignUpInvitesForm
