import React from "react"
import { RouteComponentProps } from "@reach/router"

import SignUpUserForm from "./SignUpUserForm/SignUpUserForm"
import SignUpBenefits from "./SignUpBenefits/SignUpBenefits"
import { ReactComponent as QuadioLogo } from "images/logo.svg"

import Styles from "../SignUp.module.css"

export interface SignUpPageProps extends RouteComponentProps { }

const SignUpPage: React.FC<SignUpPageProps> = ({ navigate }) => {
    return (
        <div className="container flex flex-wrap w-full mx-auto">
            <div className="flex flex-wrap w-1/2 px-10 tablet:px-20 py-16 overflow-x-hidden">
                <QuadioLogo className={Styles.logo} />
                <SignUpUserForm navigate={navigate}/>
            </div>
            <div className={`flex w-1/2`}>
                <SignUpBenefits />
            </div>
        </div>
    )
}

export default SignUpPage
