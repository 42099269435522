import { useState, useCallback } from "react"
import { gameAdapter } from "global/ApiWorker/Api"
import { useApi } from "hooks/useApi"
import { navigate } from "@reach/router"

export interface GameEndProps {
    game: number
}

export const useGameEndLogic = (game: GameEndProps["game"]) => {
    const [smile, setSmile] = useState(false)

    const getGameStatistics = useCallback(() => {
        if (!game) {
            return Promise.resolve(null)
        }

        return gameAdapter.getGameStatistics(game)
    }, [game])

    const { data, isLoading } = useApi(getGameStatistics)

    const onDoneRating = useCallback(async (restart: boolean = false) => {
        if (!restart) {
            await navigate("/")
            return
        }

        if (!data) {
            return
        }

        const { game: gameStatistics } = data

        await navigate(`/play/start/${gameStatistics.category_id}`)
    }, [data])

    const onChangeRating = useCallback(
        (rating: number) => {
            setSmile(rating >= 4)
        },
        [setSmile]
    )

    return {
        isLoading,
        data,
        onDoneRating,
        smile,
        onChangeRating
    }
}
