import { TFunction } from "i18next"
import { useContext, useCallback } from "react"
import LoadingContext from "context/LoadingProvider"
import AuthContext from "context/AuthProvider"
import { navigate } from "@reach/router"

export const useHeaderLogic = (t: TFunction) => {
    const { setSaved } = useContext(LoadingContext)
    const { authentication, user, logout: logoutAuth } = useContext(AuthContext)

    const logout = useCallback(() => {
        logoutAuth()
        navigate && navigate("/logout")
    }, [logoutAuth])

    const logoutUser = useCallback(() => {
        logout()
        setSaved({
            show: true,
            title: t("users.logout.title", "Auf wiedersehen!"),
            text: t(
                "users.logout.text",
                "Wir freuen uns Sie schon bald wieder zu sehen."
            )
        })
    }, [t, setSaved, logout])

    return {
        authentication,
        user,
        logoutUser
    }
}
