import React from "react"

import { ReactComponent as Spinner } from "svg/signup-loading.svg"

import Styles from "./SignUpLoading.module.css"

interface SignUpLoadingProps {
    title?: string
    text?: string
}

const SignUpLoading: React.FC<SignUpLoadingProps> = ({ title = false, text = false }) => {
    return <div className="container mx-auto">
        <div className="flex flex-wrap justify-center items-center h-screen">
            <div>
                <div className={Styles.wrapper} >
                    <Spinner className={Styles.spinner} />
                </div>
                {title && <p className="w-full text-center font-bold mb-2">{title}</p>}
                {text && <p className="w-full text-center opacity-25">{text}</p>}
            </div>
        </div>
    </div>
}

export default SignUpLoading
