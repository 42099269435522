import localforage from "localforage"
import slugify from "slugify"

import { IterableStorageBase } from "@interweberde/prima-core"

export default class BrowserStorage<T = any> extends IterableStorageBase {
    private store: LocalForage

    constructor(name: string) {
        super()

        this.store = localforage.createInstance({
            name: `prima-store-${slugify(name)}`,
            version: 1,
        })
    }

    async setItem(key: string, value: any): Promise<void> {
        await this.store.setItem(key, value)

        return
    }

    getItem(key: string): Promise<any> {
        return this.store.getItem(key)
    }

    async removeItem(key: string): Promise<void> {
        await this.store.removeItem(key)

        return
    }

    clear(): Promise<void> {
        return this.store.clear()
    }

    async isEmpty(): Promise<boolean> {
        return (await this.store.length()) === 0
    }

    keys(): Promise<string[]> {
        return this.store.keys()
    }
}
