export interface GameEndCategoryStatisticProps {
    categoryStatistics: any
}

export const useGameEndCategoryStatisticLogic = (categoryStatistics: GameEndCategoryStatisticProps["categoryStatistics"]) => {
    const sumDuration = categoryStatistics.sumDuration

    const skill = categoryStatistics.skill

    const questions = categoryStatistics.categoryOperationsStatistics || []

    return {
        sumDuration,
        skill,
        questions
    }
}
