import { useState, useCallback, useEffect } from "react"
import { gameAdapter } from "global/ApiWorker/Api"
import { useApi } from "hooks/useApi"

export interface GameWrapperProps {
    gameId?: number
}

export const useGameWrapperLogic = (gameId: GameWrapperProps["gameId"]) => {
    const [game, setGame] = useState<number | null>(null)
    const [gameEnded, setGameEnded] = useState<boolean | null>(null)

    const fetchGame = useCallback(async () => {
        if (!gameId) {
            return Promise.resolve(null)
        }

        return gameAdapter.get(gameId)
    }, [gameId])

    const { data } = useApi(fetchGame, {
        name: "GameWrapper",
    })

    useEffect(() => {
        if (!data) {
            return
        }

        const {
            game: { id },
            gameEnded,
        } = data
        setGame(id)
        setGameEnded(gameEnded)
    }, [data])

    const onGameEnd = useCallback(() => {
        setGameEnded(true)
    }, [setGameEnded])

    return {
        game,
        gameEnded,
        onGameEnd
    }
}
