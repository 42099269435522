import React from "react"

import { Link } from "@reach/router"
import { SearchResultProps } from "hooks/logic/SearchField/useSearchFieldLogic"

import { ReactComponent as ChevronRight } from "svg/chevron-right-gray.svg"
import { CategoryModel } from "global/ext/know/model/CategoryAdapter"
import { SearchResult } from "global/SearchService/SearchServiceAdapter"

export const useKnowCategoryResultLogic = (result: SearchResult) => {
    return result.payload as CategoryModel
}

const KnowCategoryResult: React.FC<SearchResultProps> = ({ result, mode }) => {
    const topic = useKnowCategoryResultLogic(result)

    return (
        <Link className="flex w-full justify-between" to={result.link}>
            <div className="mr-auto my-auto">
                <p className="font-sans text-sm">{topic.name}</p>
                {topic.categorygroup && (
                    <p className="font-sans mt-1 italic text-xs text-gray-slightlydark">
                        {topic.categorygroup.name}
                    </p>
                )}
            </div>
            <div className="ml-auto my-auto">
                <ChevronRight />
            </div>
        </Link>
    )
}

export default KnowCategoryResult
