import React, { Suspense } from "react"
import "typeface-open-sans"

import "./App.css"

import { AuthProvider } from "context/AuthProvider"

import Route from "./global/Route"

import Backend from "./pages/Backend/Backend"
import Frontend from "./pages/Frontend/Frontend"
import Header from "global/Header/Header"
import { LoadingProvider } from "context/LoadingProvider"
import ResetPassword from "global/ResetPassword/ResetPassword"
import RestorePassword from "global/RestorePassword/RestorePassword"
import ValidateMail from "global/ValidateMail/ValidateMail"
import ContextLoading from "global/ContextLoading"
import LoadingPopup from "global/Popups/LoadingPopup"
import SavePopup from "global/Popups/SavePopup"
import FailPopup from "global/Popups/FailPopup"
import { NotFound } from "pages/General/Error"
import ErrorBoundary from "global/ErrorBoundary"
import LogoutPage from "./global/LogoutPage/LogoutPage"
import AccessibilityRouter from "global/AccessibilityRouter"
import { LoginLoadingOverlay } from "./global/LoginOverlay/Login"
import SignUp from "global/SignUp/SignUp"

const App: React.FC = () => {
    return (
        <ErrorBoundary>
            <LoadingProvider>
                <Suspense fallback={<LoginLoadingOverlay />}>
                    <AuthProvider>
                        <div className="flex flex-col min-h-screen overflow-x-hidden font-sans">
                            <Header />
                            <Suspense fallback={<ContextLoading />}>
                                <AccessibilityRouter>
                                    <Route
                                        path="/*"
                                        component={Frontend}
                                        authenticated
                                    />
                                    <ResetPassword path="/reset-password" />
                                    <SignUp path="/sign-up/*" />
                                    <ValidateMail path="/validate-mail/:token" />
                                    <RestorePassword path="/restore-password/:token" />
                                    <Route
                                        path="/logout"
                                        component={LogoutPage}
                                    />
                                    <Route
                                        path="/backend/*"
                                        component={Backend}
                                        authenticated
                                    />
                                    <NotFound default />
                                </AccessibilityRouter>
                            </Suspense>
                        </div>
                        <LoadingPopup />
                        <SavePopup />
                        <FailPopup />
                    </AuthProvider>
                </Suspense>
            </LoadingProvider>
        </ErrorBoundary>
    )
}

export default App
