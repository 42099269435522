import React from "react"
import { useTranslation } from "react-i18next"

import Button from "global/Button/Button"

import { ReactComponent as QuadioLogo } from "images/logo.svg"

import { useSignUpInvitesFormLogic, SignUpInvitesProps } from "hooks/logic/SignUp/useSignUpInvitesFormLogic"
import SignUpStep from "../SignUpStep/SignUpStep"
import SignUpInvitesForm from "./SignUpInvitesForm/SignUpInvitesForm"

import Styles from "../SignUp.module.css"

const SignUpInvites: React.FC<SignUpInvitesProps> = ({ token, navigate }) => {
    const [t] = useTranslation()
    const { skip } = useSignUpInvitesFormLogic(token, t)

    return (
        <div className="container mx-auto">
            <div className="flex justify-between items-center w-full px-20 py-16 pb-0 mb-16">
                <QuadioLogo className={Styles.logo} />
                <Button
                    appearance={"transparent"}
                    onClick={skip}
                    rounded={false}
                >
                    {t("global.skip", "Überspringen")}
                </Button>
            </div>
            <div className="flex flex-col w-1/2 mx-auto">
                <h2 className="flex mx-auto mb-4 text-2xl">{t("signup.invites.title", "Willkommen!")}</h2>
                <p className="mx-auto">{t("signup.invites.text", "Welche Kollegen sollen in Quadio spielen?")}</p>
            </div>
            <SignUpStep step={3} max={3} />
            <SignUpInvitesForm />
        </div>
    )
}

export default SignUpInvites
