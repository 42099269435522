import React, {
    ComponentProps,
    ComponentType,
    useEffect,
    useState,
} from "react"
import ReactDom from "react-dom"

export default function withPortal(parentElem: HTMLElement = document.body) {
    return function<T>(Component: ComponentType<T>) {
        return (props: ComponentProps<typeof Component>) => {
            const [defaultNode, setDefaultNode] = useState<
                HTMLDivElement | undefined
            >()

            useEffect(() => {
                if (!document || !document.body) {
                    return
                }

                const node = document.createElement("div")
                parentElem.appendChild(node)

                setDefaultNode(node)

                return () => {
                    parentElem.removeChild(node)
                }
            }, [])

            if (!defaultNode) {
                return null
            }

            return ReactDom.createPortal(<Component {...props} />, defaultNode)
        }
    }
}
