import { useCallback, useState, ChangeEventHandler, useMemo } from "react"

import { searchAdapter } from "global/ApiWorker/Api"
import useStatefulApi from "hooks/useStatefulApi"
import { SearchResult } from "global/SearchService/SearchServiceAdapter"

export interface SearchResultProps {
    result: SearchResult
    mode: "frontend" | "backend"
}

export interface SearchFieldProps {
    mode: "frontend" | "backend"
    placeholder?: string
}

export const useSearchFieldLogic = (mode: SearchFieldProps["mode"]) => {
    const makeRequest = useCallback((value) => {
        return searchAdapter.search(value, mode)
    }, [mode])
    const [searchValue, setSearchValue] = useState("")

    const { data, isLoading, fetchData } = useStatefulApi(makeRequest, [])

    const onSearch: ChangeEventHandler<HTMLInputElement> = useCallback(
        ({ currentTarget: { value } }) => {
            setSearchValue(value)
            fetchData(value)
        },
        [fetchData]
    )

    const { resultsCount, results } = useMemo(() => {
        if (!data) {
            return {
                resultsCount: 0,
                results: []
            }
        }

        const resultsCount = data
            .map(x => x.results)
            .reduce((sum, x) => sum + x, 0)

        return {
            resultsCount,
            results: data
        }
    }, [data])

    return {
        searchResults: results,
        searchValue,
        resultsCount,
        isLoading,
        onSearch
    }
}
