import React from "react"

import ContactRequestNotification from "global/NotificationDropdown/Notifications/ContactRequestNotification"
import DefaultNotification from "global/NotificationDropdown/Notifications/DefaultNotification"
import { NotificationProps } from "hooks/logic/NotificationDropdown/useNotificationLogic"

const Notification: React.FC<NotificationProps> = props => {
    const { type } = props.notification

    if (type === "contact-request") {
        return <ContactRequestNotification {...props} />
    }

    return <DefaultNotification {...props} />
}

export default Notification
