import React, { ReactNode } from "react"
import { Manager, Reference, Popper, PopperChildrenProps } from "react-popper"

import Styles from "./StyledTooltip.module.css"
import Caret from "../Dropdown/Caret"

const StyledTooltip: React.FC<{
    reference?: ReactNode
    tooltip?: ReactNode
    tooltipStyle?: React.CSSProperties
    placement?: PopperChildrenProps["placement"]
    arrowColorClass?: string
}> = ({ reference, tooltip, tooltipStyle, placement = "top", arrowColorClass = "text-white" }) => {
    return <div className={[Styles.tooltipWrapper].join(" ")} >
        <Manager>
            <Reference>
                {({ ref }) => (
                    <div
                        className="flex"
                        ref={ref}
                    >
                        {reference}
                    </div>
                )}
            </Reference>
            <Popper
                placement={placement}
                positionFixed={true}
                modifiers={{
                    preventOverflow: {
                        enabled: true
                    },
                    hide: {
                        enabled: true
                    }
                }}
            >
                {({
                    ref,
                    style,
                    placement,
                    arrowProps,
                }) => (
                    <div
                        ref={ref}
                        className={[Styles.tooltip].join(" ")}
                        style={{
                            ...style,
                            ...tooltipStyle
                        }}
                        data-placement={placement}
                    >
                        {tooltip}

                        <Caret
                            className={arrowColorClass}
                            placement={placement}
                            ref={arrowProps.ref}
                            style={arrowProps.style}
                        />
                    </div>
                )}
            </Popper>
        </Manager>
    </div>
}

export default StyledTooltip
