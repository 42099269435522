import React, { useEffect } from "react"
import { useAuth } from "hooks/useAuth"
import { UserModel } from "@interweberde/prima-core"
import { useTranslation } from "react-i18next"
import { LoginLoadingOverlay } from "global/LoginOverlay/Login"

import BrowserStorage from "global/ApiWorker/BrowserStorage"

const defaultValue: {
    authentication: boolean | null
    user: UserModel | null
    refetchUser: () => Promise<void>
    logout: () => Promise<void>
} = {
    authentication: null,
    user: null,
    refetchUser: () => Promise.resolve(),
    logout: () => Promise.resolve(),
}

const storage = new BrowserStorage("settings")

const AuthContext = React.createContext(defaultValue)

export const AuthProvider: React.FC = ({ children }) => {
    const { i18n } = useTranslation()

    const { authenticated, user, refetchUser, logout } = useAuth("AuthProvider")

    useEffect(() => {
        if (!user) {
            ;(async () => {
                let lang = (await storage.getItem("lang")) || navigator.language

                if (!lang) {
                    return
                }

                ;[lang] = lang.split("-")

                if (i18n.language === lang) {
                    return
                }

                await i18n.changeLanguage(lang)
            })()

            return
        }

        if (i18n.language === user.lang) {
            console.log("language already set", user.lang)

            return
        }

        console.log("changing language to", user.lang)

        i18n.changeLanguage(user.lang).then(() => {
            console.log("changed language")

            return storage.setItem("lang", user.lang)
        })
    }, [i18n, user])

    const providerValue = {
        authentication: authenticated,
        user,
        refetchUser,
        logout,
    }

    return (
        <AuthContext.Provider value={providerValue}>
            {authenticated == null && <LoginLoadingOverlay />}
            {authenticated !== null && children}
        </AuthContext.Provider>
    )
}

export default AuthContext
