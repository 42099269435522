import React from "react"
import { useTranslation, Trans } from "react-i18next"

import NotificationDate from "../NotificationDate"
import Button from "global/Button/Button"
import { NotificationProps } from "hooks/logic/NotificationDropdown/useNotificationLogic"
import { useContactRequestNotificationLogic } from "hooks/logic/NotificationDropdown/Notifications/useContactRequestNotificationLogic"

const ContactRequestNotification: React.FC<NotificationProps> = ({
    notification,
    onClick,
}) => {
    const [t] = useTranslation()
    const {
        accept,
        name
    } = useContactRequestNotificationLogic(t, notification, onClick)

    return (
        <>
            <div className="flex pt-2 pr-3 w-5">
                <div className={`h-2 w-2 ${!notification.seen ? "bg-primary" : "bg-gray-background"} rounded-full`} />
            </div>
            <div className="w-2/3">
                <p>
                    <Trans i18nKey="notifications.contactrequest.text">
                        <span className="font-bold">{{ name }}</span> möchte
                        Sie als Kollege hinzufügen.
                    </Trans>
                </p>
                <NotificationDate date={notification.created} />
            </div>
            <div className="w-1/3 self-center text-right">
                {!notification.done && (
                    <Button
                        appearance="notification"
                        className="ml-auto"
                        onClick={accept(notification.payload.id)}
                    >
                        {t(
                            "notifications.contact.accept.link",
                            "Hinzufügen"
                        )}
                    </Button>
                )}
                {notification.done && (
                    <p className="ml-auto text-gray-slightlydark">
                        {t(
                            "notifications.contact.accepted.text",
                            "Hinzugefügt"
                        )}
                    </p>
                )}
            </div>
        </>
    )
}

export default ContactRequestNotification
