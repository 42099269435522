import React from "react"

import GameWrapper from "pages/Frontend/Dashboard/Play/GameWrapper"
import GetGame from "pages/Frontend/Dashboard/Play/GetGame"

import Styles from "./Play.module.css"
import { NotFound } from "pages/General/Error"
import AccessibilityRouter from "global/AccessibilityRouter"
import { PlayProps, usePlayLogic } from "hooks/logic/Frontend/Play/usePlayLogic"

const Play: React.FC<PlayProps> = ({ navigate }) => {
    const { onReceivedGame } = usePlayLogic(navigate)
    return (
        <div className="game-overlay fixed h-full w-full inset-0 bg-white overflow-y-scroll z-20">
            <div className={Styles.gameFullHeight}>
                <AccessibilityRouter>
                    <GetGame
                        path="start/:topicId"
                        onReceivedGame={onReceivedGame}
                    />
                    <GameWrapper path="game/:gameId" />
                    <NotFound default />
                </AccessibilityRouter>
            </div>
        </div>
    )
}

export default Play
