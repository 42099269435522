import React from "react"
import { Popper } from "react-popper"

import withPortal from "global/withPortal"
import { PopdownNodeProps, usePopdownNodeLogic } from "hooks/logic/Popdown/usePopdownNodeLogic"

const PopdownNode: React.FC<PopdownNodeProps> = ({ children, ...props }) => {
    const {
        open,
        setPopdownNode,
        childFn
    } = usePopdownNodeLogic(children)

    if (!open) {
        return null
    }

    return (
        <Popper {...props} innerRef={setPopdownNode}>
            {childFn}
        </Popper>
    )
}

export default PopdownNode

const node = document.createElement("div")
node.id = "portal__popdown-node"
document.body.appendChild(node)

export const PopdownNodePortal = withPortal(node)(PopdownNode)
