import React, { ComponentProps, ErrorInfo } from "react"
import Error from "pages/General/Error"

interface State {
    type: ComponentProps<typeof Error>["type"]
    hasError: boolean
}

interface Props {}

const debug = require("debug")("quadio:error")
export default class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { hasError: false, type: "server" }
    }

    static getDerivedStateFromError(error: Error) {
        // TODO: detect error type
        return {
            type: "server",
            hasError: true,
        }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        debug(error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            return <Error type={this.state.type} />
        }

        return this.props.children
    }
}
