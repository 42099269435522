import React from "react"

import Styles from "./Loading.module.css"

interface LoadingProps {
    className?: string
    size?: number
}

const Loading: React.FC<LoadingProps> = ({ className, size }) => {
    const classNames = [
        "flex",
        "w-full",
        "h-full",
        "align-content-center",
        "justify-center",
    ]

    if (className) {
        classNames.push(className)
    }

    const scale = (size || 40) / 40

    const style = {
        transform: `scale3d(${scale}, ${scale}, ${scale})`,
    }

    return (
        <div className={classNames.join(" ")} style={style}>
            <div className={Styles.spinner} />
        </div>
    )
}

export default Loading
