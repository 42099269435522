import { DateTime } from "luxon"

export interface NotificationDateProps {
    date: DateTime
}

export const useNotificationDateLogic = (date: NotificationDateProps["date"]) => {
    const today = DateTime.local().startOf("day")
    const isToday = date.startOf("day").equals(today)
    const isYesterday = date.plus({ day: 1 }).startOf("day").equals(today)

    return {
        isToday,
        isYesterday
    }
}
