import { useContext, useEffect } from "react"
import ApiContext from "context/LoadingProvider"

export const useContextLoadingLogic = () => {
    const { setLoading } = useContext(ApiContext)

    useEffect(() => {
        setLoading(true)

        return () => {
            setLoading(false)
        }
    }, [setLoading])
}
