import { TFunction } from "i18next"
import { useState, useCallback } from "react"

import { RouteComponentProps } from "@reach/router"

export interface SignUpDepartmentsProps extends RouteComponentProps { }

interface InvitesInterface {
    id: number
    email: string
    department: number | ""
}

export interface SignUpInvitesRouteParams {
    token: string
}

export interface SignUpInvitesProps
    extends RouteComponentProps<SignUpInvitesRouteParams> { }

export interface SignUpInvitesFormProps
    extends RouteComponentProps<SignUpInvitesRouteParams> { }

export const useSignUpInvitesFormLogic = (
    token: SignUpInvitesFormProps["token"],
    t: TFunction
) => {
    const [invites, setInvites] = useState<InvitesInterface[]>([
        {
            id: 1,
            email: "",
            department: ""
        },
        {
            id: 2,
            email: "",
            department: ""
        },
        {
            id: 3,
            email: "",
            department: ""
        }
    ])

    const handleSubmit = useCallback(() => {

    }, [])

    const skip = useCallback(() => {

    }, [])

    const isTokenValid = useCallback(() => {
        return true
    }, [])

    const addEntry = useCallback(() => {
        const newKey = invites.length + 1

        setInvites([
            ...invites,
            {
                id: newKey,
                email: "",
                department: ""
            }
        ])
    }, [invites])

    const updateInvite = useCallback((id: string, value: string) => {
        setInvites(invites => invites.map(elem => {
            if (elem.id === parseInt(id)) {

                return {
                    id: parseInt(id),
                    email: value,
                    department: elem.department
                }
            }

            return elem
        }))
    }, [])

    const updateDepartment = useCallback((id: string, value: number) => {
        setInvites(invites => invites.map(elem => {
            if (elem.id === parseInt(id)) {

                return {
                    id: parseInt(id),
                    email: elem.email,
                    department: value
                }
            }

            return elem
        }))
    }, [])

    return {
        handleSubmit,
        isTokenValid,
        skip,
        addEntry,
        updateInvite,
        updateDepartment,

        invites,
        setInvites
    }
}
