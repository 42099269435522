export interface GameEndGameStatisticProps {
    gameStatistics: any,
    categoryStatistics: any
}

export const useGameEndGameStatisticLogic = (
    gameStatistics: GameEndGameStatisticProps["gameStatistics"],
    categoryStatistics: GameEndGameStatisticProps["categoryStatistics"]
) => {
    const duration = gameStatistics.duration

    const operations = gameStatistics.operations.map((
            o: {
                question_id: number,
                question_skill: number
            }
        ) => {
            const qSkill = (categoryStatistics.categoryOperationsStatistics.find((
                stat: {
                    id: number
                }
            ) => stat.id === o.question_id).correct_count / 5) * 100

            o.question_skill = qSkill > 100 ? 100 : qSkill
            return o
        }
    )

    const skill = gameStatistics.percent_done || 0

    return {
        duration,
        operations,
        skill
    }
}
