import React from "react"
import Loading from "global/Loading/Loading"

import { GetGameProps, useGetGameLogic } from "hooks/logic/Frontend/Play/useGetGameLogic"

const GetGame: React.FC<GetGameProps> = ({ topicId, onReceivedGame }) => {

    useGetGameLogic(topicId, onReceivedGame)

    return <Loading />
}

export default GetGame
