import { useContext, useMemo, useCallback } from "react"

import { TFunction } from "i18next"

import LoadingContext from "context/LoadingProvider"

export interface SaveNotification {
    show: boolean
    title?: string
    text?: string
}

export interface SavePopupProps extends React.HTMLProps<HTMLDivElement> {
    appearance?: string
}

export const useSavePopupLogic = (t: TFunction) => {
    const { saved, setSaved } = useContext(LoadingContext)

    const title = t("popups.saved.title", "Gespeichert!")
    const text = t(
        "popups.saved.text",
        "Ihre Änderungen wurden gespeichert"
    )

    const saveDisplay = useMemo(() => {
        return {
            show: saved.show,
            title: saved.title || title,
            text: saved.text || text,
        }
    }, [saved.show, saved.title, saved.text, title, text])

    const closeModal = useCallback(() => {
        setSaved({
            show: false,
            title: saved.title || title,
            text: saved.text || text,
        })
    }, [setSaved, saved.title, saved.text, title, text])

    return {
        saveDisplay,
        closeModal,
        saved
    }
}
