import React from "react"
import { useTranslation } from "react-i18next"

import Button from "../Button/Button"

import { OverlayBoxWithBg } from "global/OverlayBox/OverlayBox"
import { ValidateMailProps, useValidateMailLogic } from "hooks/logic/ValidateMail/useValidateMailLogic"

const ValidateMail: React.FC<ValidateMailProps> = ({ token, navigate }) => {
    const [t] = useTranslation()

    const {
        user,
        data,
        isLoading
    } = useValidateMailLogic(token)

    const backToLogin = () => {
        navigate && navigate("/")
    }

    if (!token) {
        navigate && navigate("/")
    }

    if (!data && !isLoading) {
        navigate && navigate("/")
    }

    return (
        <OverlayBoxWithBg>
            <div className="mt-4">
                <h2 className="mb-8 text-3xl">
                    {t("validatemail.title", "E-Mail bestätigen")}
                </h2>
                <p className="mb-8">
                    {t(
                        "validatemail.button.dashboard",
                        "Vielen Dank! Ihre E-Mail ist nun bestätigt. Sie können QUADIO nun uneingeschränk nutzen."
                    )}
                </p>
            </div>
            <hr className="text-gray" />
            <div className="my-8">
                <Button
                    onClick={backToLogin}
                    className="w-full"
                    appearance="primary"
                >
                    {user && (
                        <p>
                            {t(
                                "validatemail.button.dashboard",
                                "Zur Übersicht"
                            )}
                        </p>
                    )}
                    {!user && (
                        <p>{t("validatemail.button.login", "Zum Login")}</p>
                    )}
                </Button>
            </div>
        </OverlayBoxWithBg>
    )
}

export default ValidateMail
