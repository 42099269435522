import { useState, useContext, useRef, useCallback } from "react"

import { categoryratingAdapter } from "global/ApiWorker/Api"
import LoadingContext from "context/LoadingProvider"
import { TFunction } from "i18next"


export interface RateTopicProps {
    category: number
    onChange?: (rating: number) => void
    onRatingSent?: (restart: boolean) => void
}

const ratingLimit = 4

export const useRateTopicLogic = (
    category_id: RateTopicProps["category"],
    t: TFunction,
    onChange: RateTopicProps["onChange"],
    onRatingSent: RateTopicProps["onRatingSent"]
) => {
    const [rating, setRating] = useState(0)
    const [feedbackSent, setFeedbackSent] = useState(false)
    const { setSaved } = useContext(LoadingContext)
    const feedback = useRef<HTMLTextAreaElement>(null)

    const updateRating = useCallback(
        (id: number) => () => {
            setRating(id + 1)

            onChange && onChange(id + 1)
        },
        [onChange]
    )

    const sendFeedback = useCallback(
        (restart: boolean = false) => async () => {
            if (feedbackSent) {
                return
            }

            if (!rating) {
                return onRatingSent && onRatingSent(restart)
            }

            setFeedbackSent(true)

            categoryratingAdapter
                .add({
                    category_id,
                    rating,
                    comment:
                        rating > ratingLimit || !feedback.current
                            ? ""
                            : feedback.current.value,
                })
                .then()

            setSaved({
                show: true,
                title: t("game_end.feedback.done.title", "Feedback gesendet!"),
                text: t(
                    "game_end.feedback.done.text",
                    "Ihr Feedback wurde erfolgreich gesendet."
                ),
            })

            onRatingSent && onRatingSent(restart)
        },
        [feedbackSent, rating, category_id, setSaved, t, onRatingSent]
    )

    return {
        rating,
        feedback,
        sendFeedback,
        updateRating
    }
}
