import React, { PropsWithChildren } from "react"

import { ReactComponent as BurgerMenuSign } from "svg/burger-menu-sign.svg"

import Styles from "./BurgerMenu.module.css"
import { useCallback } from "react"
import { useState } from "react"
import MenuPopupBackend from "./MenuPopupBackend"
import MenuPopupFrontend from "./MenuPopupFrontend"
import { UserModel } from "@interweberde/prima-core"
import { LinkProps, Link, LinkGetProps } from "@reach/router"

interface BurgerMenuProps {
    user: UserModel,
    logout: () => void,
    backend?: boolean
}

export interface MenuPopupProps {
    user: UserModel,
    onClose: () => void,
    logout: () => void,
    backend?: boolean
}

export interface BurgerMenuNavigationLinkProps extends LinkProps < {} > {
    appearance: string
    text: string
    exact?: boolean
}

const isActive = (classes: string[], exact: boolean) => ({
    isCurrent,
    isPartiallyCurrent,
}: LinkGetProps) => {
    const classNames = [...classes]

    if (isCurrent || (!exact && isPartiallyCurrent)) {
        classNames.push(Styles.active)
    }

    return { className: classNames.join(" ") }
}

export const BurgerMenuNavigationLink: React.FC<BurgerMenuNavigationLinkProps> = ({
    text,
    appearance,
    to,
    exact,
    children
}) => {
    const classes: string[] = [Styles.burgerMenuLink]

    if (appearance) {
        classes.push(Styles[appearance])
    }

    return (
        <Link to={to} getProps={isActive(classes, !!exact)}>
            {children}
            {text}
        </Link>
    )
}

const BurgerMenu: React.FC<BurgerMenuProps> = ({
    user,
    logout,
    backend = false
}: PropsWithChildren<BurgerMenuProps>) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const toggleMenu = useCallback(() => {
        setMenuOpen(menuOpen => !menuOpen)
    }, [setMenuOpen])

    return (
        <>
            <button
                className={backend ? Styles.burgerMenuTogglerBackend : Styles.burgerMenuToggler}
                onClick={toggleMenu}
            >
                <BurgerMenuSign
                    className={backend ? Styles.iconBackend : Styles.icon}
                />
            </button>
            <div className="mobile:hidden">
                {menuOpen && backend && <MenuPopupBackend onClose={() => setMenuOpen(false)} logout={logout} user={user}/>}
                {menuOpen && !backend && <MenuPopupFrontend onClose={() => setMenuOpen(false)} logout={logout} user={user}/>}
            </div>
        </>
    )
}

export default BurgerMenu
