import { useState, useEffect, useCallback } from "react"
import * as zxcvbn from "zxcvbn"

export interface PasswordStrengthProps {
    password: string
}

export const usePasswordStrengthLogic = (password: PasswordStrengthProps["password"])=> {
    const [strength, setStrength] = useState<number>(0)

    const calculateStrength = useCallback((password) => {
        setStrength(zxcvbn.default(password).score)
    }, [])

    useEffect(() => {
        calculateStrength(password)
    }, [calculateStrength, password])

    return { strength }
}
