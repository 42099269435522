import * as Comlink from "comlink"
import { Remote } from "comlink"
import ApiWorker, { ApiWorkerInterface } from "./Api.worker"
import handlers from "global/ApiWorker/transferHandlers"

type ThenArg<T> = T extends Promise<infer U> ? U : T
type FixComlinkType<T> = {
    [k in keyof T]: Remote<ThenArg<T[k]>>
}

handlers.forEach((value, key) => {
    Comlink.transferHandlers.set(key, value)
})

const worker = new ApiWorker()
const mistypedApi = Comlink.wrap<ApiWorkerInterface>(worker)

const api = (mistypedApi as unknown) as FixComlinkType<typeof mistypedApi>

// CORE
/// Auth
export const authAdapter = api.authAdapter

export const signupAdapter = api.signupAdapter

export const userAdapter = api.userAdapter
export const classificationAdapter = api.classificationAdapter
export const groupAdapter = api.groupAdapter
export const roleAdapter = api.roleAdapter
export const usersContactAdapter = api.usersContactAdapter
export const companyAdapter = api.companyAdapter

// KNOW
export const categoryAdapter = api.categoryAdapter
export const categorygroupAdapter = api.categorygroupAdapter
export const categoryratingAdapter = api.categoryratingAdapter
export const statisticAdapter = api.statisticAdapter
export const gameAdapter = api.gameAdapter
export const questionAdapter = api.questionAdapter

// HELP
export const helpArticleAdapter = api.helpArticleAdapter
export const helpCategoryAdapter = api.helpCategoryAdapter

// SBUSCRIPTIONS
export const subscriptionAdapter = api.subscriptionAdapter
export const mandateAdapter = api.mandateAdapter

// SEARCH
export const searchAdapter = api.searchAdapter

// NOTIFICATION
export const notificationAdapter = api.notificationAdapter
