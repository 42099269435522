import React from "react"

import Styles from "./Progress.module.css"

type ProgressProps = {
    value?: number
    max?: number
    appearance?: string,
    label?: boolean,
    labelText?: string
}

const Progress: React.FC<ProgressProps> = ({ value, max, appearance, label = false, labelText }) => {
    const classNames = [Styles.inner, Styles.indeterminate]

    if (appearance) {
        classNames.push(Styles[appearance])
    }

    if (value === undefined || max === undefined) {
        return (
            <div className={Styles.outer} role="progressbar">
                <div
                    className={[Styles.inner, Styles.indeterminate].join(" ")}
                />
            </div>
        )
    }

    const width = Math.min((value / max) * 100, 100)

    const innerBarStyle = {
        width: `${width}%`,
    }

    return <>
        <div
            className={Styles.outer}
            role="progressbar"
            aria-valuemin={0}
            aria-valuenow={value}
            aria-valuemax={max}
        >
            <div
                className={classNames.join(" ").trim()}
                style={innerBarStyle}
            />
        </div>
        {label && labelText && <div className="text-14 font-bold ml-4">{labelText}</div>}
    </>
}

export default Progress
