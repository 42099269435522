import React from "react"
import Modal from "global/Modal/Modal"

import { ReactComponent as Logo } from "svg/logo.svg"

import { ReactComponent as LearningSign } from "svg/learning-sign.svg"
import { ReactComponent as ColleaguesSign } from "svg/colleagues-sign.svg"
import { ReactComponent as MyStatisticsSign } from "svg/my-statistics-sign.svg"
import { ReactComponent as HelpSign } from "svg/help-sign.svg"
import { ReactComponent as LogoutSign } from "svg/logout.svg"
import { ReactComponent as OptionsSign } from "svg/options-sign.svg"
import { ReactComponent as LayerSign } from "svg/layer.svg"

import Styles from "./BurgerMenu.module.css"

import { useTranslation } from "react-i18next"
import { MenuPopupProps, BurgerMenuNavigationLink } from "./BurgerMenu"
import { Link } from "@reach/router"

const MenuPopup: React.FC<MenuPopupProps> = ({ user, onClose, logout, backend = false }) => {
    const [t] = useTranslation()

    return (
        <Modal
            className="flex flex-col overflow-y-scroll"
            onClose={onClose}
        >
            {({ closeButton }) => {
                return (
                    <>
                        <div className={Styles.burgerMenuWrapper}>
                            <Logo />
                            {closeButton}
                        </div>
                        <div className="flex flex-row flex-wrap text-center py-4 mb-0">
                            <div className="flex flex-row flex-wrap w-full">
                                <BurgerMenuNavigationLink
                                    appearance="frontend"
                                    text={t("header.frontend.learn", "Lernen")}
                                    to="/"
                                    exact
                                >
                                    <LearningSign className="stroke-current mr-3"/>
                                </BurgerMenuNavigationLink>

                                <BurgerMenuNavigationLink
                                    appearance="frontend"
                                    text={t("header.frontend.my_statistic", "Meine Statistik")}
                                    to="/user/statistics"
                                >
                                    <MyStatisticsSign className="stroke-current mr-3" />
                                </BurgerMenuNavigationLink>

                                <BurgerMenuNavigationLink
                                    appearance="frontend"
                                    text={t("header.frontend.colleagues", "Kollegen")}
                                    to="/leaderboards"
                                >
                                    <ColleaguesSign className="stroke-current mr-3" />
                                </BurgerMenuNavigationLink>

                                <BurgerMenuNavigationLink
                                    appearance="frontend"
                                    text={t("header.frontend.help", "Hilfe")}
                                    to="/help"
                                >
                                    <HelpSign className="stroke-current mr-3" />
                                </BurgerMenuNavigationLink>

                                <BurgerMenuNavigationLink
                                    appearance="frontend"
                                    text={t("header.dropdown.menu.settings", "Einstellungen")}
                                    to="/user/settings"
                                    exact
                                >
                                    <OptionsSign className="stroke-current mr-3" />
                                </BurgerMenuNavigationLink>
                                <button
                                    className={Styles.mobileLogoutButton}
                                    onClick={logout}
                                >
                                    <LogoutSign className="stroke-current mr-3" />
                                    {t("header.dropdown.menu.logout", "Logout")}
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-row flex-wrap mt-auto w-full mb-5">
                            {user.is_admin && <Link
                                className="flex mx-auto text-primary text-14 p-4"
                                to="/backend"
                            >
                                <LayerSign className="stroke-current mr-3" />{t("header.frontend.to_backend", "Zum Admin-Bereich")}
                            </Link>}
                        </div>
                    </>
                )
            }}
        </Modal>
    )
}

export default MenuPopup
