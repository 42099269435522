import React from "react"

import DefaultSearchResult from "global/SearchField/SearchResults/DefaultSearchResult"
import UserSearchResult from "global/SearchField/SearchResults/UserSearchResult"
import KnowCategoryResult from "./SearchResults/KnowCategoryResult"
import { SearchResultProps } from "hooks/logic/SearchField/useSearchFieldLogic"

const RenderSearchResult: React.FC<SearchResultProps> = ({ result, mode }) => {
    if (result.type === "user") {
        return <UserSearchResult mode={mode} result={result} />
    }

    if (result.type === "know-category") {
        return <KnowCategoryResult mode={mode} result={result} />
    }

    return <DefaultSearchResult mode={mode} result={result} />
}

export default RenderSearchResult
