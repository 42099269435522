import { TFunction } from "i18next"
import { useState, useContext, useCallback, useEffect } from "react"
import LoadingContext from "context/LoadingProvider"
import { RouteComponentProps } from "@reach/router"
import { signupAdapter } from "global/ApiWorker/Api"
import { SignupModel } from "global/ext/model/SignupAdapter"

export interface SignUpUserFormProps extends RouteComponentProps { }

export const useSignUpUserFormLogic = (t: TFunction) => {
    const [firstname, setFirstname] = useState<string>("")
    const [surname, setSurname] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [mandantor, setMandantor] = useState<string>("")
    const [data, setData] = useState<SignupModel | null>(null)
    const [agbAccepted, setAgbAccepted] = useState<boolean>(false)
    const { setSaved, setFailed } = useContext(LoadingContext)

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(async event => {
        event.preventDefault()

        if (!data) {
            return
        }

        const result = await signupAdapter.signup(data)

        if (!result) {
            setFailed({
                show: true,
                title: t("signup.fail.title", "Registrierung fehlgeschlagen"),
                text: t("signup.fail.text", "Bitte überprüfen Sie Ihre Daten!"),
            })

            return
        }

        setSaved({
            show: true,
            title: t("signup.success.title", "Herzlichen Glückwunsch"),
            text: t("signup.success.text", "Sie wurden erfolgreich registriert!"),
        })
    }, [data, setFailed, setSaved, t])

    const getToken = useCallback(async () => {
        return "a-valid-token"
    }, [])

    useEffect(() => {
        setData({
            name: firstname,
            surname: surname,
            mandantorName: mandantor,
            email: email,
            password: password,
        })
    }, [email, firstname, mandantor, password, surname])

    return {
        handleSubmit,
        getToken,

        firstname,
        setFirstname,

        surname,
        setSurname,

        email,
        setEmail,

        mandantor,
        setMandantor,

        password,
        setPassword,

        agbAccepted,
        setAgbAccepted
    }
}
