import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import DisplayDuration from "global/DisplayDuration"
import { ReactComponent as LearningSign } from "svg/learning-sign.svg"
import { ReactComponent as CheckmarkCircleSign } from "svg/checkmark-circle-open.svg"
import { ReactComponent as ClockSign } from "svg/clock.svg"
import { ReactComponent as StatisticSign } from "svg/statistic.svg"

import { GameEndGameStatisticProps, useGameEndGameStatisticLogic } from "hooks/logic/Frontend/Play/GameEnd/useGameEndGameStatisticLogic"
import { useGameEndCategoryStatisticLogic } from "hooks/logic/Frontend/Play/GameEnd/useGameEndCategoryStatisticLogic"

import Style from "./GameEnd.module.css"
import StyledTooltip from "global/Tooltip/StyledTooltip"

const GameEndGameStatistic: React.FC<GameEndGameStatisticProps> = ({
    gameStatistics,
    categoryStatistics
}) => {
    const [t] = useTranslation()
    const [showDetails, setShowDetails] = useState(false)
    const { duration, operations } = useGameEndGameStatisticLogic(gameStatistics, categoryStatistics)
    const { skill } = useGameEndCategoryStatisticLogic(categoryStatistics)

    return (
        <div className="w-full">
            <div className="py-10">
                <div className="flex justify-center tablet:-mx-4">
                    <div className="flex w-full flex-wrap mobile:flex-no-wrap tablet:w-2/3">
                        <div className="flex w-full mobile:w-1/3 flex-col mx-2 self-start my-2">
                            <div className="bg-secondary text-primary rounded-lg p-4">
                                <CheckmarkCircleSign className="stroke-current mb-4" />
                                <span className="flex">
                                    <p className="text-3xl">
                                        {gameStatistics.answered_questions}/{gameStatistics.number_of_questions}
                                    </p>
                                </span>
                                <span className="flex text-14 tablet:text-normal tablet:font-bold">
                                    {t(
                                        "game.end.statistics.game.questions_correct",
                                        {
                                            defaultValue: "Richtig beantwortet",
                                        }
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className="flex w-full mobile:w-1/3 flex-col mx-2 self-start my-2">
                            <div className="bg-yellow-light text-yellow rounded-lg p-4">
                                <ClockSign className="stroke-current mb-4" />
                                <span className="flex">
                                    <p className="text-3xl">
                                        <DisplayDuration short seconds={duration} />
                                    </p>
                                </span>
                                <span className="flex text-14 tablet:text-normal tablet:font-bold">
                                    {t(
                                        "game.end.statistics.game.duration",
                                        "Spielzeit"
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className="flex w-full mobile:w-1/3 flex-col mx-2 self-start my-2">
                            <div className="bg-green-background text-green rounded-lg p-4">
                                <StatisticSign className="stroke-current mb-4" />
                                <span className="flex">
                                    <p className="text-3xl">
                                        {skill}%
                                    </p>
                                </span>
                                <span className="flex text-14 tablet:text-normal tablet:font-bold">
                                    {t(
                                        "game.end.statistics.category.mastered.overall",
                                        {
                                            defaultValue: "Thema erlernt",
                                        }
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex w-full justify-center">
                <div
                    className={`flex text-14 font-bold ${showDetails ? "text-gray" : ""}`}
                    onClick={() => setShowDetails(!showDetails)}
                    role="button"
                >
                    <LearningSign className="stroke-current mr-2" />
                    {
                        showDetails
                        ? t("game.statistics.game_statistics.tab.hide", "Durchgang ausblenden")
                        : t("game.statistics.game_statistics.tab.show", "Durchgang zeigen")
                    }
                </div>
            </div>

            {showDetails && <div className="flex flex-wrap tablet:-mx-4 mt-8">
                {operations.map(
                    (operation: {
                        question: any
                        result: number
                        id: number
                        question_skill: number
                    }) => {
                        const question = operation.question
                        if (!question) {
                            return null
                        }

                        const correctClasses = ["mt-6 self-end font-bold"]

                        if (operation.result) {
                            correctClasses.push(Style.correct)
                        } else {
                            correctClasses.push(Style.wrong)
                        }

                        const wrapperClasses = [
                            "flex flex-wrap h-full w-full p-5 rounded-lg text-left",
                        ]

                        if (operation.result) {
                            wrapperClasses.push(Style.correctBg)
                        } else {
                            wrapperClasses.push(Style.wrongBg)
                        }

                        return (
                            <div
                                className="w-full mobile:w-1/2 laptop:w-1/3 desktop:w-1/4 p-2"
                                key={`question-result-${operation.id}-outer`}
                            >
                                <div
                                    className={wrapperClasses.join(" ")}
                                    key={`question-result-${operation.id}`}
                                >
                                    <span className="w-full italic">{question.question}</span>
                                    <p className={`${correctClasses.join(" ")} mr-auto`}>
                                        {operation.result && t("game.end.statistics.operation.correct", "Richtig")}
                                        {!operation.result && t("game.end.statistics.operation.wrong", "Falsch")}
                                    </p>
                                    <p className={`${correctClasses.join(" ")} ml-auto`}>
                                        <StyledTooltip
                                            placement="top"
                                            tooltipStyle={{
                                                width: "220px",
                                                top: "15px"
                                            }}
                                            reference={<div className={`${correctClasses.join(" ")} ml-auto cursor-pointer`}>
                                                {t("game.end.statistics.operation.learned", {
                                                    skill: operation.question_skill
                                                })}
                                            </div>}
                                            arrowColorClass="text-secondary"
                                            tooltip={<div className="text-xl p-4 rounded-lg bg-secondary">
                                                <p className="text-sm text-primary font-bold mb-2">
                                                    {t("game.end.statistics.operation.tooltip.title", "Erlernt")}
                                                </p>
                                                <p className="text-sm text-primary font-normal">
                                                    {t("game.end.statistics.operation.tooltip.text", "Eine Frage gilt als wissenschaftlich erlernt, sobald sie 5 mal richtig beantwortet wurde.")}
                                                </p>
                                            </div>}
                                        />
                                    </p>
                                </div>
                            </div>
                        )
                    }
                )}
            </div>}
        </div>
    )
}

export default GameEndGameStatistic
