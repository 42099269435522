import React, { useState, useEffect, useCallback } from "react"

import { FailNotification } from "hooks/logic/Popups/useFailPopupLogic"
import { SaveNotification } from "hooks/logic/Popups/useSavePopupLogic"

const defaultValue: {
    setLoading: (loading: boolean) => void
    loading: boolean

    setSaved: (saved: SaveNotification) => void
    saved: SaveNotification

    setFailed: (failed: FailNotification) => void
    failed: FailNotification
} = {
    setLoading: () => {},
    loading: false,

    setSaved: () => {},
    saved: { show: false },

    setFailed: () => {},
    failed: { show: false },
}

const LoadingContext = React.createContext(defaultValue)

interface LoadingProviderProps {}

const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
    const [loading, setLoading] = useState(false)
    const [saved, setSaved] = useState<SaveNotification>({ show: false })
    const [failed, setFailed] = useState<FailNotification>({ show: false })
    const [activePopups, setActivePopups] = useState(0)

    useEffect(() => {
        if (!activePopups || (!saved.show && !failed)) {
            return
        }

        const timeoutId = setTimeout(() => {
            setSaved({
                ...saved,
                show: false,
            })
            setFailed({
                ...failed,
                show: false,
            })

            setActivePopups(0)
        }, 3000)

        return () => {
            clearTimeout(timeoutId)
        }
    }, [activePopups, failed, saved])

    const setSavedOverride = useCallback(
        (value: SaveNotification) => {
            setSaved(value)

            if (!value) {
                setActivePopups(Math.max(activePopups - 1, 0))
            } else {
                setActivePopups(activePopups + 1)

                if (failed.show) {
                    setFailed({ show: false })
                    setActivePopups(0)
                }
            }
        },
        [activePopups, failed, setFailed, setSaved]
    )

    const setFailedOverride = useCallback(
        (value: FailNotification) => {
            setFailed(value)

            if (!value) {
                setActivePopups(Math.max(activePopups - 1, 0))
            } else {
                setActivePopups(activePopups + 1)

                if (saved.show) {
                    setSaved({ show: false })
                    setActivePopups(0)
                }
            }
        },
        [activePopups, saved, setFailed, setSaved]
    )

    const providerValue = {
        loading,
        saved,
        failed,
        setLoading: setLoading,
        setSaved: setSavedOverride,
        setFailed: setFailedOverride,
    }

    return (
        <LoadingContext.Provider value={providerValue}>
            {children}
        </LoadingContext.Provider>
    )
}

export { LoadingProvider }
export default LoadingContext
