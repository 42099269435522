import { TFunction } from "i18next"
import { useState, useCallback, useEffect } from "react"

import { RouteComponentProps } from "@reach/router"

interface DepartmentsInterface {
    id: number
    name: string
}

export interface SignUpDepartmentsRouteParams {
    token: string
}

export interface SignUpDepartmentsProps
    extends RouteComponentProps<SignUpDepartmentsRouteParams> { }

export const useSignUpDepartmentsLogic = (
    token: SignUpDepartmentsProps["token"],
    t: TFunction
) => {
    const [loading, setLoading] = useState(true)
    const [departments, setDepartments] = useState<DepartmentsInterface[]>([
        {
            id: 1,
            name: "",
        },
        {
            id: 2,
            name: "",
        },
        {
            id: 3,
            name: "",
        }
    ])

    const addEntry = useCallback(() => {
        const newKey = departments.length + 1

        setDepartments([
            ...departments,
            {
                id: newKey,
                name: ""
            }
        ])
    }, [departments])

    const handleSubmit = useCallback(() => {

    }, [])

    const skip = useCallback(() => {

    }, [])

    const isTokenValid = useCallback(() => {
        return true
    }, [])

    const updateDepartment = useCallback((id: string, value: string) => {
        setDepartments(departments => departments.map(elem => {
            if (elem.id === parseInt(id)) {

                return {
                    id: parseInt(id),
                    name: value
                }
            }

            return elem
        }))
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }, [])

    return {
        handleSubmit,
        skip,
        addEntry,
        updateDepartment,
        isTokenValid,

        loading,

        departments,
        setDepartments
    }
}
