import React from "react"
import { useTransition, animated } from "react-spring"
import { useTranslation } from "react-i18next"

import { ReactComponent as Crossmark } from "svg/crossmark-red.svg"

import Styles from "./LoadingPopup.module.css"
import Button from "../Button/Button"
import { FailPopupProps, useFailPopupLogic } from "hooks/logic/Popups/useFailPopupLogic"

const FailPopup: React.FC<FailPopupProps> = ({
    children,
    appearance,
    className,
}) => {
    const [t] = useTranslation()

    const {
        closeModal,
        failDisplay,
        failed
    } = useFailPopupLogic(t)

    const classNames = [Styles.popup, Styles.popupWide, Styles.red]

    const transitions = useTransition(failed.show, null, {
        initial: { transform: "translate3d(0, 0px, 0)" },
        from: { transform: "translate3d(0, 120px, 0)" },
        enter: { transform: "translate3d(0, 0px, 0)" },
        leave: { transform: "translate3d(0, 120px, 0)" },
        trail: 300,
    })

    return (
        <>
            {transitions.map(
                ({ item, key, props }) =>
                    item && (
                        <animated.div
                            className={classNames.join(" ").trim()}
                            style={props}
                            key={key}
                        >
                            <Crossmark
                                className={["hidden mobile:block ml-0", Styles.failIcon].join(" ")}
                            />
                            <div className="px-4 mx-auto mobile:mx-0">
                                <span className="block text-xl">
                                    {failDisplay.title}
                                </span>
                                <span className="hidden mobile:block">
                                    {failDisplay.text}
                                </span>
                            </div>
                            <div className="hidden mobile:flex ml-auto">
                                <Button
                                    onClick={closeModal}
                                    appearance="red-dark"
                                >
                                    {t("popups.failed.close", "Schließen")}
                                </Button>
                            </div>
                        </animated.div>
                    )
            )}
        </>
    )
}

export default FailPopup
