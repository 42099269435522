import React from "react"

import { SearchResultProps } from "hooks/logic/SearchField/useSearchFieldLogic"

import { ReactComponent as ChevronRight } from "svg/chevron-right-gray.svg"

const DefaultSearchResult: React.FC<SearchResultProps> = ({ result }) => {
    return (
        <>
            <div className="mr-auto my-auto">
                <p className="font-sans text-sm">{result.title}</p>
                <p className="font-sans mt-1 italic text-xs text-gray-slightlydark">
                    {result.text}
                </p>
            </div>
            <div className="ml-auto my-auto">
                <ChevronRight />
            </div>
        </>
    )
}

export default DefaultSearchResult
