import React from "react"
import { useTranslation } from "react-i18next"

import { NotificationDateProps, useNotificationDateLogic } from "hooks/logic/NotificationDropdown/useNotificationDateLogic"

const NotificationDate: React.FC<NotificationDateProps> = ({ date }) => {
    const [t] = useTranslation()
    const {
        isToday,
        isYesterday
    } = useNotificationDateLogic(date)

    if (isToday) {
        return (
            <p className="mt-2 text-14 text-primary font-bold">
                {t("notification.contact.date.today", "Heute")}
            </p>
        )
    }

    if (isYesterday) {
        return (
            <p className="mt-2 text-14 text-gray italic">
                {t("notification.contact.date.yesterday", "Gestern")}
            </p>
        )
    }

    return (
        <p className="mt-2 text-14 text-gray italic">
            {date.toLocaleString()}
        </p>
    )
}

export default NotificationDate
