import React, { forwardRef, useMemo } from "react"
import Style from "./Button.module.css"

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    appearance?: string
    textTransform?: "uppercase" | "normal-case" | "lowercase" | "capitalize"
    rounded?: boolean
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        { appearance, textTransform, rounded = true, children, className, ...buttonProps },
        ref
    ) => {
        const extendedClassName = useMemo(() => {
            const classNames = [
                Style.button,
                textTransform || "normal-case",
                "px-6",
                "py-4",
            ]

            if (appearance) {
                classNames.push(Style[appearance])
            }

            if (className) {
                classNames.push(className)
            }

            if (rounded) {
                classNames.push(Style.rounded)
            }

            return classNames.join(" ").trim()
        }, [appearance, className, rounded, textTransform])

        return (
            <button {...buttonProps} ref={ref} className={extendedClassName}>
                {children}
            </button>
        )
    }
)

export default Button
