import { useCallback, useRef } from "react"

export function useWaitUntilDone<T>(cb: () => Promise<T>, deps: any[] = []) {
    const running = useRef(false)
    const memoizedCb = useCallback(cb, deps)

    return useCallback(async () => {
        if (running.current) {
            return
        }

        running.current = true

        try {
            const res = await memoizedCb()

            running.current = false

            return res
        } catch (e) {
            running.current = false

            throw e
        }
    }, [memoizedCb])
}
