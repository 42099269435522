import React from "react"
import { RouteComponentProps } from "@reach/router"
import Loading from "global/Loading/Loading"
import QuestionWrapper from "pages/Frontend/Dashboard/Play/QuestionWrapper/QuestionWrapper"
import GameEnd from "pages/Frontend/Dashboard/Play/GameEnd/GameEnd"

import { useGameWrapperLogic, GameWrapperProps } from "hooks/logic/Frontend/Play/useGameWrapperLogic"

const GameWrapper: React.FC<RouteComponentProps<GameWrapperProps>> = ({ gameId }) => {
    const {
        game,
        gameEnded,
        onGameEnd
    } = useGameWrapperLogic(gameId)

    if (!game) {
        return <Loading />
    }

    if (gameEnded) {
        return <GameEnd game={game} />
    }

    return <QuestionWrapper game={game} onGameEnd={onGameEnd} />
}

export default GameWrapper
