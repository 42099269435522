import { RouteComponentProps } from "@reach/router"
import { useContext, useCallback } from "react"
import AuthContext from "context/AuthProvider"
import { userAdapter } from "global/ApiWorker/Api"
import { useApi } from "hooks/useApi"

export interface ValidateMailRouteParams {
    token: string
}

export interface ValidateMailProps
    extends RouteComponentProps<ValidateMailRouteParams> {}

export const useValidateMailLogic = (token: ValidateMailProps["token"]) => {
    const { user } = useContext(AuthContext)

    const validateMail = useCallback(() => {
        if (!token) {
            return Promise.reject()
        }

        return userAdapter.validateMail(token)
    }, [token])

    const { data, isLoading } = useApi(validateMail)

    return {
        user,
        data,
        isLoading
    }
}
