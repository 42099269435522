import React from "react"
import { useTransition, animated } from "react-spring"
import { useTranslation } from "react-i18next"

import { useSavePopupLogic, SavePopupProps } from "hooks/logic/Popups/useSavePopupLogic"

import { ReactComponent as Checkmark } from "svg/checkmark-green.svg"
import Styles from "./LoadingPopup.module.css"
import Button from "../Button/Button"

const SavePopup: React.FC<SavePopupProps> = ({
    children,
    appearance,
    className,
}) => {
    const [t] = useTranslation()

    const {
        saveDisplay,
        closeModal,
        saved
    } = useSavePopupLogic(t)

    const classNames = [Styles.popup, Styles.popupWide, Styles.green]

    const transitions = useTransition(saved.show, null, {
        initial: { transform: "translate3d(0, 0px, 0)" },
        from: { transform: "translate3d(0, 120px, 0)" },
        enter: { transform: "translate3d(0, 0px, 0)" },
        leave: { transform: "translate3d(0, 120px, 0)" },
        trail: 300,
    })

    return (
        <>
            {transitions.map(
                ({ item, key, props }) =>
                    item && (
                        <animated.div
                            className={classNames.join(" ").trim()}
                            style={props}
                            key={key}
                        >
                            <Checkmark
                                className={["hidden mobile:block ml-0", Styles.saveIcon].join(" ")}
                            />
                            <div className="px-4 mx-auto mobile:mx-0">
                                <span className="block text-xl">
                                    {saveDisplay.title}
                                </span>
                                <span className="hidden mobile:block">
                                    {saveDisplay.text}
                                </span>
                            </div>
                            <div className="hidden mobile:flex ml-auto">
                                <Button
                                    onClick={closeModal}
                                    appearance="green-dark"
                                >
                                    {t("popups.saved.close", "Schließen")}
                                </Button>
                            </div>
                        </animated.div>
                    )
            )}
        </>
    )
}

export default SavePopup
