import React from "react"

import Styles from "./SearchField.module.css"
import { ReactComponent as LenseSign } from "svg/lense-sign.svg"

import Dropdown from "global/Dropdown/Dropdown"
import RenderSearchResult from "global/SearchField/RenderSearchResult"
import { useTranslation } from "react-i18next"
import PopdownManager from "global/Popdown/PopdownManager"
import PopdownReference from "global/Popdown/PopdownReference"
import PopdownNode from "global/Popdown/PopdownNode"

import { useSearchFieldLogic, SearchFieldProps } from "hooks/logic/SearchField/useSearchFieldLogic"

const SearchField: React.FC<SearchFieldProps> = ({ mode, placeholder }) => {
    const [t] = useTranslation()
    const {
        searchResults,
        searchValue,
        resultsCount,
        isLoading,
        onSearch
    } = useSearchFieldLogic(mode)

    return (
        <div className="flex relative">
            <PopdownManager>
                <PopdownReference>
                    {({ setOpen }) => ({ ref }) => (
                        <>
                            <LenseSign
                                className={
                                    mode === "backend"
                                        ? Styles.lenseSignBackend
                                        : Styles.lenseSign
                                }
                            />
                            <input
                                type="search"
                                placeholder={placeholder}
                                className={`
                                    ${mode === "backend"
                                        ? Styles.searchBackend
                                        : Styles.search}
                                `}
                                onChange={onSearch}
                                onClick={() => {
                                    setOpen(true)
                                }}
                                ref={ref}
                            />
                        </>
                    )}
                </PopdownReference>
                <PopdownNode>
                    {() => ({ ref, style, placement, arrowProps }) => (
                        <>
                            {isLoading && searchValue !== "" && (
                                <div
                                    className={Styles.searchResults}
                                    ref={ref}
                                    style={{
                                        ...style
                                    }}
                                >
                                    <Dropdown className="rounded-none mobile:rounded-xl">
                                        <div className={Styles.searchLoading}>
                                            <div className={Styles.spinner} />
                                        </div>
                                    </Dropdown>
                                </div>
                            )}
                            {!isLoading && searchValue !== "" && searchResults && (
                                <div
                                    className={Styles.searchResults}
                                    ref={ref}
                                    style={{
                                        ...style
                                    }}
                                >
                                    <Dropdown className="rounded-none mobile:rounded-xl">
                                        <ol>
                                            {searchResults.map(group => {
                                                if (!group.data.length) {
                                                    return null
                                                }

                                                return <li
                                                    key={`searchgroup-${group.id}`}
                                                >
                                                    <p className="bg-gray-background text-gray border-b border-gray-muted px-4 py-2 text-xs uppercase font-bold font-sans">
                                                        {group.title} (
                                                        {group.data.length})
                                                    </p>
                                                    <ol>
                                                        {group.data.map(searchResult => {
                                                            return <li
                                                                className={
                                                                    Styles.searchResult
                                                                }
                                                                key={`search-${group.id}-${searchResult.id}`}
                                                            >
                                                                <RenderSearchResult
                                                                    mode={mode}
                                                                    result={searchResult}
                                                                />
                                                            </li>
                                                        })}
                                                    </ol>
                                                </li>
                                            })}
                                        </ol>

                                        {!resultsCount && (
                                            <div className="py-8 px-4 text-center">
                                                <p className="font-bold mb-4">
                                                    {t("search.no_results.title", "Keine Suchergebnisse")}
                                                </p>
                                                <p className="font-sans opacity-50 text-sm">
                                                    {t(
                                                        "search.no_results.text",
                                                        {
                                                            defaultValue:
                                                                "Leider haben wir keine Ergebnisse für “{{searchValue}}” gefunden.",
                                                            searchValue: searchValue,
                                                        }
                                                    )}
                                                </p>
                                            </div>
                                        )}
                                    </Dropdown>
                                </div>
                            )}
                        </>
                    )}
                </PopdownNode>
            </PopdownManager>
        </div>
    )
}

export default SearchField
