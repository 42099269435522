import React from "react"
import { useTranslation } from "react-i18next"

import Button from "../Button/Button"
import ButtonStyleLink from "global/Button/ButtonStyleLink"

import { OverlayBoxWithBg } from "global/OverlayBox/OverlayBox"
import Loading from "global/Loading/Loading"
import { LoginProps, useLoginLogic } from "hooks/logic/Login/useLoginLogic"

const Login: React.FC<LoginProps> = () => {
    const [t] = useTranslation()
    const {
        handleSubmit,

        username,
        setUsername,

        password,
        setPassword
    } = useLoginLogic(t)

    const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> = ({
        currentTarget: { value },
    }) => {
        setUsername(value)
    }

    const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = ({
        currentTarget: { value },
    }) => {
        setPassword(value)
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <h2 className="mb-8 text-3xl">{t("login.title", "Login")}</h2>
                <input
                    className="input focus:outline-none focus:shadow-outline"
                    id="username"
                    type="text"
                    placeholder={t(
                        "login.form.placeholder.username",
                        "E-Mail Adresse"
                    )}
                    onChange={handleUsernameChange}
                    value={username}
                    autoComplete={"username"}
                />
            </div>
            <div className="mb-8">
                <input
                    className="input focus:outline-none focus:shadow-outline"
                    id="password"
                    type="password"
                    placeholder={t(
                        "login.form.placeholder.password",
                        "Passwort"
                    )}
                    onChange={handlePasswordChange}
                    value={password}
                    autoComplete={"current-password"}
                />
            </div>
            <hr className="text-gray" />
            <div className="mb-2 mt-8">
                <Button type="submit" className="w-full justify-center mobile:justify-start" appearance="primary">
                    {t("login.button.login", "Einloggen")}
                </Button>
            </div>
            <div className="mb-6 px-6 flex text-center">
                <ButtonStyleLink
                    className="w-full justify-center mobile:justify-start"
                    appearance="transparent"
                    to="/reset-password"
                >
                    {t("login.button.forgotpassword", "Kennwort vergessen")}
                </ButtonStyleLink>
            </div>
        </form>
    )
}

export const LoginOverlay: React.FC<LoginProps> = props => {
    return (
        <OverlayBoxWithBg>
            <Login {...props} />
        </OverlayBoxWithBg>
    )
}

export const LoginLoadingOverlay: React.FC = () => {
    return (
        <div className="absolute top-0 left-0 w-full h-full m-0 p-0 flex items-center justify-center">
            <Loading className="m-auto -mt-10 items-center" />
        </div>
    )
}

export default Login
