import React from "react"
import { useTranslation } from "react-i18next"

import { ReactComponent as Checkmark } from "svg/checkmark-circle-blue.svg"

import Styles from "./SignUpBenefits.module.css"

export interface SignUpBenefitsProps { }

const SignUpBenefits: React.FC<SignUpBenefitsProps> = () => {
    const [t] = useTranslation()

    const benefits = [
        t("signup.benefits.start_immediately", "Sofort loslegen"),
        t("signup.benefits.trial", "14 Tage kostenlos testen"),
        t("signup.benefits.measurable_learning", "Messbare Lern-Erfolge"),
        t("signup.benefits.unlimited_user_imports", "Uneingeschränkt Benutzer importieren"),
        t("signup.benefits.cross_plattform", "Online auf jedem Gerät aufrufbar"),
    ]

    return (
        <div className={`${Styles.overlay}`}>
            <div className={`${Styles.wrapper}`}>
                <h1 className="mb-4">{t("signup.benefits.title", "Ihre Vorteile")}</h1>
                {benefits.map((item, key) => {
                    return <div key={key} className="flex flex-row items-center my-3">
                        <Checkmark className="mr-4" />
                        <p>{item}</p>
                    </div>
                })}
            </div>
        </div>
    )
}

export default SignUpBenefits
