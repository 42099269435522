import React, { useCallback, useEffect, useState } from "react"
import set from "lodash/set"
import { authAdapter } from "global/ApiWorker/Api"
import { proxy } from "comlink"
import BrowserStorage from "global/ApiWorker/BrowserStorage"

const noop = async () => {}
type UserSettingsContextType = {
    set: (key: string, value: any) => Promise<void>
    data: any
}

const UserSettingsContext = React.createContext<UserSettingsContextType>({
    set: noop,
    data: {},
})

const storage = new BrowserStorage("userSettings")

export const UserSettingsProvider: React.FC = ({ children }) => {
    const [data, setData] = useState({})

    const updateLocal = useCallback(async () => {
        const newData: any = {}
        const keys = await storage.keys()
        for (const key of keys) {
            set(newData, key, await storage.getItem(key))
        }

        setData(newData)
    }, [setData])

    const setValue = useCallback(
        async (key, value) => {
            await storage.setItem(key, value)

            await updateLocal()
        },
        [updateLocal]
    )

    useEffect(() => {
        updateLocal()

        let token: string

        authAdapter
            .subscribe(
                "logout",
                proxy(async () => {
                    await storage.clear()
                })
            )
            .then(id => {
                token = id
            })

        return () => {
            if (!token) {
                return
            }

            authAdapter.unsubscribe("logout", token).then()
        }
    }, [updateLocal])

    const providerValue = {
        set: setValue,
        data,
    }

    return (
        <UserSettingsContext.Provider value={providerValue}>
            {children}
        </UserSettingsContext.Provider>
    )
}

export default UserSettingsContext
