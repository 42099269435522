import React, {
    MouseEventHandler,
    ReactElement,
    useCallback,
    useMemo,
} from "react"
import { ReactComponent as CloseIcon } from "svg/times.svg"

import Style from "./Modal.module.css"
import { useTranslation } from "react-i18next"
import withPortal from "global/withPortal"

export type ModalVoidMethod = (...args: any[]) => void

export type ModalChild<CloseMethodType extends ModalVoidMethod> = React.FC<{
    closeButton: ReactElement
    onClose: CloseMethodType
}>

export interface ModalProps<CloseMethodType extends ModalVoidMethod> {
    className?: string
    onClose?: CloseMethodType
    children: ModalChild<CloseMethodType>
    width?: boolean
    fixed?: boolean
}

const noop = () => {}

function Modal<T extends ModalVoidMethod>({
    children: Children,
    onClose,
    className,
    width,
    fixed,
}: ModalProps<T>) {
    const [t] = useTranslation()

    const modalClass = useMemo(() => {
        const classes = [Style.modal, className]

        return classes.join(" ")
    }, [className])

    const triggerClose = useMemo(() => {
        if (!onClose) {
            return noop as T
        }

        return onClose
    }, [onClose])

    const triggerBackdropClose: MouseEventHandler<HTMLDivElement> = useCallback(
        ({ target, currentTarget }) => {
            if (!onClose) {
                return
            }

            if (!(target instanceof Node)) {
                return
            }

            if (!currentTarget.isSameNode(target)) {
                return
            }

            onClose()
        },
        [onClose]
    )

    return (
        <div className={Style.backdrop} onClick={triggerBackdropClose}>
            <div className={modalClass}>
                <Children
                    onClose={triggerClose}
                    closeButton={
                        <button
                            className={`mr-0 ${Style.closeModalButton}`}
                            onClick={triggerClose}
                        >
                            <CloseIcon />
                            <span className="hidden" aria-hidden={false}>
                                {t("modal.close", "Schließen")}
                            </span>
                        </button>
                    }
                />
            </div>
        </div>
    )
}

export default Modal
export const GlobalModal = withPortal()(Modal)
