import { useState, useCallback, useEffect } from "react"
import { QuadioNotification } from "global/NotificationService/NotificationService"
import useAbortable from "hooks/useAbortable"
import { notificationAdapter } from "global/ApiWorker/Api"
import { proxy } from "comlink"

export interface NotificationDropdownProps {
    backend?: boolean
}

const asyncNoop = async () => { }

export const useNotificationDropdownLogic = (backend?: NotificationDropdownProps["backend"]) => {
    const [unreadNotifications, setUnreadNotifications] = useState(0)
    const [notifications, setNotifications] = useState<QuadioNotification[]>([])
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const isBackend = backend || false

    const rejectWhenUnmounted = useAbortable(asyncNoop)

    const fetchNotifications = useAbortable(
        useCallback(() => {
            return notificationAdapter.getNotifications()
        }, [])
    )

    const refetchNotifications = useCallback(async () => {
        try {
            await notificationAdapter.refreshNotifications()
        } catch (e) {
            // ignore
        }
    }, [])

    useEffect(() => {
        let cancelToken: string
        const run = async () => {
            cancelToken = await notificationAdapter.subscribe(
                "notification",
                proxy(async unread => {
                    try {
                        await rejectWhenUnmounted()

                        setUnreadNotifications(unread)

                        setNotifications(await fetchNotifications())
                    } catch (e) {
                        // ignore error
                    }
                })
            )

            await notificationAdapter.refreshNotifications()
        }

        run()

        return () => {
            if (!cancelToken) {
                return
            }

            notificationAdapter.unsubscribe("notification", cancelToken)
        }
    }, [
        setUnreadNotifications,
        setNotifications,
        rejectWhenUnmounted,
        fetchNotifications,
    ])

    useEffect(() => {
        notificationAdapter.start().then()

        return () => {
            notificationAdapter.stop().then()
        }
    }, [])

    useEffect(() => {
        if (!isDropdownOpen) {
            return
        }

        // if at least one notification has not been seen yet
        if (!notifications.reduce((acc, val) => acc || !val.seen, false)) {
            return
        }

        const timeoutId = setTimeout(async () => {
            await Promise.all(
                notifications.map(async notification => {
                    if (notification.seen) {
                        return
                    }

                    await notificationAdapter.markSeen(notification)
                })
            )

            try {
                setNotifications(await fetchNotifications())
            } catch (e) {
                // ignore
            }
        }, 1000)

        return () => {
            clearTimeout(timeoutId)
        }
    }, [fetchNotifications, isDropdownOpen, notifications])

    return {
        setIsDropdownOpen,
        isBackend,
        unreadNotifications,
        notifications,
        refetchNotifications
    }
}
