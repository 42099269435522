import { useContext, useCallback } from "react"

import { PopperProps } from "react-popper"
import { PopdownManagerContext } from "hooks/logic/Popdown/usePopdownManagerLogic"

export interface PopdownNodeChildrenArgs {
    togglePopdown: () => void
    open: boolean
}

export interface PopdownNodeProps extends Omit<PopperProps, "children"> {
    children: (props: PopdownNodeChildrenArgs) => PopperProps["children"]
}

export const usePopdownNodeLogic = (children: PopdownNodeProps["children"]) => {
    const { setPopdownNode, togglePopdown, open } = useContext(
        PopdownManagerContext
    )

    const childFn = useCallback(children({ togglePopdown, open }), [
        children,
        togglePopdown,
        open,
    ])

    return {
        open,
        setPopdownNode,
        childFn
    }
}
