import { useState, useCallback, useEffect } from "react"
import { gameAdapter } from "global/ApiWorker/Api"
import { useApi } from "hooks/useApi"
import { useWaitUntilDone } from "hooks/useWaitUntilDone"
import { shuffle } from "lodash"

export interface Question {
    question: string
}

export interface Answer {
    id?: number
    text: string
    joker_rolled?: boolean
}

export interface FetchedAnswer extends Answer {
    id: number
}

export interface QuestionWrapperProps {
    game: number
    onGameEnd: Function
}

export const useQuestionWrapperLogic = (
    game: QuestionWrapperProps["game"],
    onGameEnd: QuestionWrapperProps["onGameEnd"]
) => {
    const [isAnswerCorrect, setIsAnswerCorrect] = useState(false)
    const [correctAnswer, setCorrectAnswer] = useState<number | null>(null)
    const [question, setQuestion] = useState<Question | null>(null)
    const [answers, setAnswers] = useState<FetchedAnswer[] | null>(null)
    const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null)
    const [gameObj, setGameObj] = useState<any | null>(null)
    const [operation, setOperation] = useState<any | null>(null)
    const [isValidated, setIsValidated] = useState(false)
    const [gameEnded, setGameEnded] = useState<boolean | null>(null)

    const getNextQuestion = useCallback(() => {
        if (game === null) {
            return Promise.resolve(null)
        }

        return gameAdapter.nextQuestion(game)
    }, [game])

    const { data, isLoading, run: fetchNextQuestion } = useApi(
        getNextQuestion,
        {
            name: "question",
        }
    )

    const sendAnswer = useWaitUntilDone(async () => {
        if (!selectedAnswer || !operation || isLoading || isValidated) {
            return
        }

        const { correctAnswer, answerCorrect } = await gameAdapter.sendAnswer(
            operation.id,
            selectedAnswer
        )

        setIsValidated(true)
        if (correctAnswer) {
            setCorrectAnswer(correctAnswer.id)
        }
        setIsAnswerCorrect(answerCorrect)
    }, [selectedAnswer, operation, isLoading, isValidated])

    const selectAnswer = useCallback(
        (answer_id: number) => () => {
            if (isValidated) {
                return
            }

            if (!answers) {
                return
            }

            const answer = answers.find(a => a.id === answer_id)

            if (!answer || answer.joker_rolled) {
                return
            }

            setSelectedAnswer(answer_id)
        },
        [isValidated, answers]
    )

    const nextQuestion = useWaitUntilDone(() => {
        if (!isValidated || isLoading) {
            return
        }

        return fetchNextQuestion()
    }, [fetchNextQuestion, isValidated, isLoading])

    const activateJoker25 = useWaitUntilDone(async () => {
        if (!operation) {
            return
        }

        if (
            gameObj.joker25 !== null ||
            gameObj.joker50 === operation.sequence
        ) {
            return
        }

        await gameAdapter.activateJoker25(operation.id)
        fetchNextQuestion()
    }, [fetchNextQuestion, operation, gameObj])

    const activateJoker50 = useWaitUntilDone(async () => {
        if (!operation) {
            return
        }

        if (gameObj.joker50 !== null) {
            return
        }

        await gameAdapter.activateJoker50(operation.id)
        fetchNextQuestion()
    }, [fetchNextQuestion, operation, gameObj])

    useEffect(() => {
        if (data === null) {
            return
        }

        const {
            currentQuestion,
            currentAnswers,
            currentOperation,
            game,
            gameEnded,
        } = data

        setQuestion(currentQuestion)
        setAnswers(shuffle(currentAnswers))
        setGameObj(game)
        setOperation(currentOperation)
        setGameEnded(gameEnded)

        setIsValidated(false)
        setSelectedAnswer(null)
    }, [
        data,
        setAnswers,
        setGameEnded,
        setGameObj,
        setIsValidated,
        setOperation,
        setQuestion,
        setSelectedAnswer
    ])

    useEffect(() => {
        if (!gameEnded) {
            return
        }

        onGameEnd()
    }, [gameEnded, onGameEnd])

    return {
        data,
        isLoading,
        sendAnswer,
        isAnswerCorrect,
        correctAnswer,
        selectAnswer,
        nextQuestion,
        activateJoker25,
        activateJoker50,

        question,
        setQuestion,

        answers,
        setAnswers,

        gameObj,
        setGameObj,

        operation,
        setOperation,

        gameEnded,
        setGameEnded,

        isValidated,
        setIsValidated,

        selectedAnswer,
        setSelectedAnswer
    }
}
